<template>
  <div class="home" 
    :class='{
      "bg-at": $store.state.user_profile.organisation.name == "AcademicTransfer",
      "bg-gc": $store.state.user_profile.organisation.name != "AcademicTransfer",
    }'
    :style="getColorStyling()">
    <Navbar />
    <div id="app-content">
      <VContainer>
      <h2 class="py-4">Manage your Account</h2>
      <div class="" id="account_management">
        {{user_profile.user.first_name}} {{user_profile.user.last_name}} ({{user_profile.organisation.name}})
        <!-- <div :key="'email'+index" v-for="(email, index) in emails">
          {{email.email}}
        </div> -->
      </div>
      <VDivider />
      <h4>Connected accounts</h4>
      <!-- ORCID -->
      <div class="orcid_connection">
        <VAlert v-if="$route.query?.error??false" :closable="true" :title="verbose[$route.query.error].title" color="danger" @click:close="close_msg($route.query.error)" variant="tonal">
          {{verbose[$route.query.error].details}}
        </VAlert>
        <div v-if="get_socialaccount('orcid')">
          <VBtn id="connected_orcid_button" prepend-icon="fa-brands fa-orcid" class="m2-4">
            {{get_socialaccount('orcid')['uid']}}
            <template v-slot:append>
              <span class="disconnect_orcid hover-red" title="Unlink your ORCID" @click="disconnect_orcid()"><VIcon class="fa fa-xmark"></VIcon></span>
            </template>
          </VBtn>
        </div>
        <div v-else>
          <form action="/api/allauth/browser/v1/auth/provider/redirect" method="POST">
            <input type="hidden" :value="csrf_token" name="csrfmiddlewaretoken">
            <input type="hidden" name="provider" value="orcid">
            <input type="hidden" name="callback_url" value="/accounts/manage/">
            <input type="hidden" name="process" value="connect">
            <button type="submit"></button>
            <VBtn id="connect_orcid_button" type="submit" class="my-2">
              Connect your ORCID profile
            </VBtn>
          </form>
        </div>
      </div>
      <!-- SAML -->
      <div v-if="get_socialaccount('surfconext')">
        <VBtn id="connected_orcid_button" prepend-icon="fa fa-key" class="m2-4">
          Connected to {{get_socialaccount('surfconext')["display"]}}
        </VBtn>
      </div>
      <VDivider />
      <UpdatePassword :username="user_profile.user.username" v-if="current_session?.user?.has_usable_password"/>
      </VContainer>
    </div>
  </div>
</template>

<script>
//dependencies
import Navbar from '../components/Navbar.vue'
import shared from '../components/shared'
import UpdatePassword from '../components/Account/UpdatePassword.vue'
import {mapState} from 'vuex'

export default {
  name: 'Manage Account',
  components: {Navbar, UpdatePassword},
  computed: {
    colorMap: shared.colorMap,
    ...mapState(['user_profile']),
  },
  created: function() {
    this.$store.commit('SET_USER',JSON.parse(document.getElementById('user-data').text));
  },
  data: function() {return({
    csrf_token: '', 
    current_session_response: {},
    config: {
      'socialaccount': {
        'providers': [],
      }
    },
    emails: [],
    linked_accounts: [],
    verbose: {
        'connected_other': {
            "title":  "Another account is already connected to this ORCID.",
            "details":  `You authenticated succesfully at ORCID, but your ORCID is
                        already connected to a different Global Campus account. Please
                        reach out to support@globalcampus.ai for help.`
},
},
  })},
  mounted: async function() {
    this.csrf_token = shared.get_cookie('csrftoken')
    const current_session_response = await this.$axiosQ.jumpQueue('gcallauth', {
      method: 'get', 
      url: "/auth/session"
    })
    this.current_session = current_session_response.data
    const config_response = await this.$axiosQ.jumpQueue('gcallauth', {
      method: 'get', 
      url: "/config"
    })
    this.config = config_response.data
    const emails_response = await this.$axiosQ.jumpQueue('gcallauth', {
      method: 'get', 
      url: "account/email"
    })
    this.emails = emails_response.data
    const providers_response = await this.$axiosQ.jumpQueue('gcallauth', {
      method: 'get', 
      url: "account/providers"
    })
    this.linked_accounts = providers_response.data
  },
  methods: {
    getColorStyling: function() {
      return shared.colorMap(this.user_profile.organisation.name)
    },
    get_socialaccount: function(id) {
      const linked_provider = this.linked_accounts.find(linked_acc => {return linked_acc.provider.id == id})
      if (_.isEmpty(linked_provider)) return false
      return linked_provider
    },
    disconnect_orcid: async function() {
      const _this = this
      const disconnect_response = await this.$axiosQ.jumpQueue('gcallauth', {
        method: 'delete', 
        url: "account/providers",
        data: {
          "provider": "orcid",
          "account": _this.get_socialaccount('orcid')['uid']
        }
      })
      this.linked_accounts = disconnect_response.data
    },
    close_msg(reason) {
      console.log('closing message ', reason)
      this.$router.push('/accounts/manage/')
    }
  },
}
</script>

<style scoped>
   .home {
    height: 100vh;
    background-color:var(--bakcground-color);
    width:100vw;
    overflow-y: hidden;
  }
  .no-decoration {
    text-decoration:none;
  }
  .query-link {
    color:initial;
  }
  .hover-orange:hover  {
    color:var(--orange_highlight);
  }
  #app-content{
      display:flex;
      width:100vw;
      min-width:800px;
      margin:auto;
      height:calc(100vh - 40px);
      overflow-y:auto;
  }
  #collections-index {
    margin: 0 auto;
    max-width: 1920px;
    width: 100vw;
  }
</style>