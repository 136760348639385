<template>
    <VRow justify="end">
        <VPagination
            variant="elevated"
            :value="page_number"
            :tile="true"
            @update:model-value="select_page"
            :length="page_count"
            density="comfortable"
            :total-visible="5"
            class="my-2 justify-end text-grey"
            :ripple="false"
            active-color="primary"
        >
        <!-- Todo: Use template to create an ellipsis button. -->
        <!-- <template v-slot:item="{key, page}">
            <VBtn v-if="key == page" 
                @click="$emit('load_page', page)"
                :class="{'active': page == page_number}"
            >{{page}}</VBtn>
            <VBtn v-else disabled class="text-white" color="primary">
                ...
                <VMenu activator="parent">
                    <VBtn>
                        {{key}}
                    </Vbtn>
                </VMenu>
            </VBtn>
        </template> -->
        </VPagination>
    </VRow>
</template>

<script>
export default {
    name: "Pagination",
    props: ["item_count", "page_size"],
    computed: {
        page_count: function () {
            return Math.ceil(
                this.item_count /
                this.page_size
            )
        },
    },
    data: function() {return({
        page_number: 1,
    })},
    methods: {
        select_page: function(selected_page){
            this.$emit('load_page', selected_page)
        },
    },
}
</script>

<style>
.v-pagination__item--is-active .v-btn__content {
    color:white!important;
}
</style>