<template>
    <div>
        <VExpansionPanels>
            <Team 
                v-for="(team, team_index) in teams"
                :key="'team'+team_index" 
                :team="team"
                :users="users"
                @delete_team="delete_team(team)"
                @refresh_data="$emit('refresh_data')"
            />
        </VExpansionPanels>
        <div v-if="!teams || !teams.length" class="no_teams">
            There are currently no teams in your organisation.
        </div>
        <!-- add team menu -->
        <VAlert class="error-message mt-2" :key="'error'+idx"  v-for="(errorMessage, idx) in errors" type="error" dense>
            {{ errorMessage }}
        </VAlert>
        <VBtn 
            v-if="!new_team_opened"
            id="create_new_team_menu_btn"
            @click="new_team_opened=!new_team_opened" 
            prepend-icon="fa fa-add" 
            class="mt-4 d-flex btn-primary"
            >
            Create a new team
        </VBtn>
        <VCard 
            class="add_new_team mt-4"
            v-if="new_team_opened"
            offset-x>
            <VTextField
                v-model="new_team.name"
                label="Team name"
                id="new_team_name"
                :rules="TeamnameRules"
                hide-details="auto"
                required
            ></VTextField>
            <VAutocomplete
                :items="users"
                id="new_team_users"
                :item-title="'first_name'"
                :item-value="'username'"
                :return-object="true"
                label="With these users"
                multiple
                :closable-chips="true"
                :flat="true"
                hide-selected
                hide-details="auto"
                chips
                v-model="new_team.users"
            >
            </VAutocomplete>
            <VBtn block class="text-primary" 
                id="create_new_team_submit_btn"
                @click="create_team()"
                :loading="new_team.loading"
                variant="elevated"
            >
                Create team
                <template :slot="new_team.loading">
                    <VProgressCircular
                        color="primary"
                        indeterminate
                    ></VProgressCircular>
                </template>
            </VBtn>
        </VCard>
    </div>
</template>

<script>
import Team from './Team.vue'

export default {
    name: "Team List",
    components: {Team},
    props: ["teams", "users"],
    computed: {
        existing_team_names() {
            return _.map(this.teams, 'name')
        },
    },
    data: function() {return ({
        errors: [],
        new_team: {
            name: '',
            users: [],
            loading: false,
        },
        new_team_opened: false,
        TeamnameRules: [
            value => {
                if (this.existing_team_names.includes(value)) {
                    return 'Team name already exists.'
                }
                return true
            },
            value => {
                if (value?.length >= 5) return true
                return 'Name must be at least 5 characters.'
            },
      ],
    })},
    methods: {
        async create_team() {
            let _this = this
            console.log('creating team:', this.new_team)
            this.new_team.loading = true
            await this.$axiosQ.jumpQueue('gcapi', {
                method: 'post', 
                url: `/api/teams/`,
                data: {
                    name: this.new_team.name,
                    users: this.new_team.users
                }
            }).catch(axios_error => {
                console.log('failed_response', axios_error)
                _this.errors.push(axios_error.response.data.detail)
            })
            this.$emit('refresh_data')
            this.new_team.loading = false
            this.new_team_opened = false
        },
        async delete_team(deleting_team) {
            console.log('deleting team', deleting_team)
            if(confirm(`Are you sure you want to delete the team ${deleting_team.name}? This action cannot be reversed. 
All items shared with this team will lose that share.`)) {
            this.deleting = true
            this.teams = this.teams.filter(team => team.uuid !== deleting_team.uuid)
            await this.$axiosQ.jumpQueue('gcapi', {
                method: 'delete', 
                url: `/api/teams/${deleting_team.uuid}/`,
            }).catch(axios_error => {
                console.log('failed_response', axios_error)
                _this.messages.push(axios_error.response.data.detail)
            })
                this.$emit('refresh_data')
                this.deleting = false
            }
        },
    }
}
</script>

<style>

</style>