<template>
  <div class="home" 
    :class='{
      "bg-at": $store.state.user_profile.organisation.name == "AcademicTransfer",
      "bg-gc": $store.state.user_profile.organisation.name != "AcademicTransfer",
    }'
    :style="getColorStyling()">
    <Navbar />
    <div id="app-content" class="d-flex flex-column flex-grow-1">
      <div class="px-2" id="collections-index">
        <h2 class="py-4">Manage teams and users in your organisation</h2>
        <div class="d-flex align-items-start justify-content-between">
          <div class="w-50 me-4 ">
            <h4>Teams</h4>
            <TeamList :teams="teams" :users="users" @refresh_data="load_team_and_user_data()"/>
          </div>
          <div class="flex-grow-1 w-50">
            <h4>All users</h4>
            <v-expansion-panels variant="accordion">
              <v-expansion-panel :key="user.username" v-for="user in users">
                <v-expansion-panel-title class="user-title almost_black">
                  <v-icon icon="fa fa-user" class="me-2 text-secondary"></v-icon>
                  {{user.first_name}} {{user.last_name}}
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  <div v-if="username_to_team[user.username]">
                    <v-chip-group>
                      Member of these teams:
                      <v-chip label :key="name" v-for="name in username_to_team[user.username]??[]">
                        {{name}}
                      </v-chip>
                    </v-chip-group>
                  </div>
                  <div v-else>Not in any teams</div>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
            <!-- <v-btn prepend-icon="fa fa-envelope" class="mt-4 d-flex btn-primary">
              Invite user
            </v-btn> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//dependencies
import Navbar from '../components/Navbar.vue'
import shared from '../components/shared'
import {mapState} from 'vuex'
import TeamList from '../components/Teams/TeamList'

export default {
  name: 'ManageTeams',
  components: {Navbar, TeamList},
  computed: {
    colorMap: shared.colorMap,
    ...mapState(['user_profile'])
  },
  created: function() {
    this.$store.commit('SET_USER',JSON.parse(document.getElementById('user-data').text));
  },
  data: function() {return({
    teams: [],
    users: [],
    username_to_team: {},
  })},
  mounted: async function() {
    await this.load_team_and_user_data()
  },
  methods: {
    load_team_and_user_data: async function() {
      const teams_reponse = await this.$axiosQ.jumpQueue('gcapi', {
        method: 'get', 
          url: "/api/teams/"
      })
      this.teams = teams_reponse.data
      this.username_to_team = {}
      this.teams.forEach(team => {
        team.users.forEach(user => {
          if (this.username_to_team[user.username]) {this.username_to_team[user.username].push(team.name)} 
          else {this.username_to_team[user.username] = [team.name]}
        })
      })
      const users_reponse = await this.$axiosQ.jumpQueue('gcapi', {
          method: 'get', 
          url: "/api/users/"
      })
      this.users = users_reponse.data
    },
    getColorStyling: function() {
      return shared.colorMap(this.user_profile.organisation.name)
    },
  },
}
</script>

<style scoped>
   .home {
    height: 100vh;
    background-color:var(--bakcground-color);
    width:100vw;
    overflow-y: hidden;
  }
  .no-decoration {
    text-decoration:none;
  }
  .query-link {
    color:initial;
  }
  .hover-orange:hover  {
    color:var(--orange_highlight);
  }
  #app-content{
      display:flex;
      width:100vw;
      min-width:800px;
      margin:auto;
      height:calc(100vh - 40px);
      overflow-y:auto;
  }
  #collections-index {
    margin: 0 auto;
    max-width: 1920px;
    width: 100vw;
  }
</style>