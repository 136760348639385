<template>
    <div class="mb-3">
        <div class="sidebar-title" title="Include or exclude abstracts on the basis of keywords or terms present in the abstract.">Abstract contains</div>
        <Multiselect
            mode="tags"
            v-model="textfilter_value"
            :searchable="true"
            :createOption="true"
            :class="{'active' : (textfilter_value.length && textfilter_value !== null)}"
            :value="'text'"
            :label="'text'"
            :appendToBody="true"
            :noOptionsText="'Type the keyword(s)'" 
            :allowAbsent="true"
            ref="textfilter"
        >
        </Multiselect>
        <FilterSlider field='abstract'/>
    </div>
</template>

<script>

export default {
    name: "TextFilter",
    computed: {
        textfilter_value: {
            get: function() {
                if (typeof this.$store.state.filters.abstract?.values === 'undefined') return []
                return this.$store.state.filters['abstract']['values'];
            },
			set: function(value) {
				this.$store.commit('SET_FILTER', {
					field: 'abstract', 
					type: this.$store.getters.getExcludedFilterFields.includes('abstract')?'exclude':'include',
					values: value}
                )	
			}
        }
    }
}
</script>

<style>

</style>