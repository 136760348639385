<template>
    <div class="p-4">
      <div class="d-flex justify-content-between">
        <h3>Sharing Collection {{shared_item.name}}</h3>
        <div id="close_topic_modal" role="button" class="mb-2 col-2" @click="$store.commit('CLOSE_MODAL')">
            <i class="fa fa-close fa-2x me-2 float-end"></i>
        </div>
      </div>
      <div class="d-flex flex-row mt-4">
        <span class="badge bg-second me-2">External identifier: {{shared_item.external_identifier}}</span>
        <span class="badge bg-second me-2">Included Records: {{shared_item.included_records.length}}</span>
        <span class="badge bg-second">Excluded Records: {{shared_item.included_records.length}}</span>
      </div>
      <div class="mt-4">
        <h5>Shared with:</h5>
        <div class="mt-2 row align-items-center">
          <label for="shared-users" class="col-sm-1">
            <i class="fa fa-user"></i> Users
          </label>
          <div class="col-sm-11">
            <Multiselect
                mode="tags"
                label="username"
                valueProp="username"
                :trackBy="['first_name', 'last_name', 'username']"
                :searchable="true"
                placeholder="Find user or team to share with"
                v-model="shared_users"
                :object="true"
                :canClear="true"
                id="shared-users" 
                :noOptionsText="'No other users are visible for you in this organisation'"
                :options="shareable_with.users"
              >
              <template v-slot:option="{ option }">
                {{ option.first_name }} {{ option.last_name }} ({{ option.username }})
              </template>
            </Multiselect>
          </div>
        </div>
        <div class="mt-2 row align-items-center">
          <label for="shared-users" class="col-sm-1">
            <i class="fa fa-user-group"></i> Teams
          </label>
          <div class="col-sm-11">
            <Multiselect
                mode="tags"
                label="name"
                valueProp="name"
                :searchable="true"
                placeholder="Find user or team to share with"
                v-model="shared_teams"
                :object="true"
                :filterResults="true"
                :canClear="false"
                :min-chars="3"
                :delay="150"
                id="shared-users" 
                :noOptionsText="'No teams are visible for you in this organisation.'"
                :options="shareable_with.teams"
              >
              <template v-slot:option="{ option }">
                {{ option.name }}
              </template>
            </Multiselect>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ShareCollection",
  computed: {
    shareable_with: {
        get() {
          return {
            'users': this.$store.state.sharing_store.users,
            'teams': this.$store.state.sharing_store.teams
          }
      }
    },
    shared_item: {
      get() {
        if(this.$store.state.modals['share_collection'].uuid) {
          return this.$store.state.searches_store.searches.find(search =>{
            return search.uuid == this.$store.state.modals['share_collection'].uuid
          })
        }
        else return {}
      },
    },
    shared_users:{
      get() {return this.shared_item['shared_users'] ?? []},
      set(value) {
        console.log('updating shared users to value:', value)
        this.shared_item['shared_users'] = value
        this.$store.commit('searches_store/UPDATE_SEARCH', this.shared_item)
        this.$store.dispatch('searches_store/patch_search', this.shared_item)
      }  
    },
    shared_teams:{
      get() {return this.shared_item['shared_teams'] ?? []},
      set(value) {
        console.log('updating shared teams to value:', value)
        this.shared_item['shared_teams'] = value
        this.$store.commit('searches_store/UPDATE_SEARCH', this.shared_item)
        this.$store.dispatch('searches_store/patch_search', this.shared_item)
      }  
    },
  },
  mounted: function() {
    this.$store.dispatch('sharing_store/get_users')
    this.$store.dispatch('sharing_store/get_teams')
  }
}
</script>

<style>
.bg-primary {
  background-color:var(--primary_color)!important;
}
.bg-second {
  background-color:var(--secondary_color)!important;
}
</style>
