<template>
    <div class="mb-3" :title="'Select institution types'">
        <div for="institution_type" class="sidebar-title">Type of institution</div>
        <Multiselect
            mode="tags" label="name" valueProp="id" :searchable="false" placeholder="Select an institution type"
            v-model="institution_type" :object="false" id="institution_type_multiselect"
            :class="{'active' : institution_type?.length}"
            :options="institution_type_select_options"
        />
        <FilterSlider field='institution.type'/>
    </div>
</template>

<script>
export default {
    name: "Institution Type Filter",
    computed: {
        institution_type: {
            get() {
                return this.$store.state.filters['institution.type']?this.$store.state.filters['institution.type']['values']:[]
            },
            set(value) {
                this.$store.dispatch('update_filter', {field_name: 'institution.type', 'values':value})
            }
        },
    },
    data: function() {return({
        institution_type_select_options: [
                {'id': 'education', 'name': 'Education'},
                {'id': 'healthcare','name': 'Healthcare'},
                {'id': 'government','name': 'Government'},
                {'id': 'facility',  'name': 'Facility'},
                {'id': 'company',   'name': 'Company'},
                {'id': 'nonprofit', 'name': 'Non-profit'},
                {'id': 'other',     'name': 'Other'},
                {'id': 'archive',   'name': 'Archive'},
        ]
    })},
}
</script>

<style>

</style>