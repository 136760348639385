<template>
    <nav class="navbar navbar-expand-lg navbar-light">
        <div class="container-fluid">
            <a class="navbar-brand" href="/app/"></a>
            <!-- Personal menu -->
            <VMenu  transition="slide-y-transition">
                <template v-slot:activator="{ props }">
                    <VListItem
                        id="open_topnav"
                        variant="text"
                        density="comfortable"
                        v-bind="props"
                        class="user-select-none"
                        :ripple="false"
                        @click="menu_open=1-menu_open"
                    >
                        <template v-slot:append="">
                            <span class="animate-flip" :class="{'flip-y': menu_open}">
                                <i class="ms-2 fa fa-chevron-down"></i>
                            </span>
                        </template>
                        <strong>{{user_profile.user.first_name}}</strong>
                        <span class="ms-2">{{user_profile.organisation.name}}</span>
                    </VListItem>
                </template>
                <VList :nav="true" :lines="false" density="compact">
                    <VListItem 
                        id="topnav_my_account"
                        to="/accounts/manage/"
                        append-icon="fa fa-user"
                        title="My Account"
                    />
                    <div v-if="user_profile.organisation.name == 'AcademicTransfer'">
                        <VListItem 
                            id="topnav_upgrade"
                            to="/app/upgrade/"
                            color="primary"
                            append-icon="fa fa-rocket"
                            title="Upgrade license"
                        />
                    </div>
                    <div v-else>
                        <VListItem 
                            id="topnav_my_collections"
                            color="primary"
                            to="/app/collections/"
                            title="My Collections"
                            append-icon="fa fa-layer-group"
                        />
                        <VListItem 
                            id="topnav_my_filter"
                            color="primary"
                            to="/app/filters/"
                            append-icon="fa fa-filter"
                            title="My Filters"
                        />
                        <VListItem v-if="user_profile.user.is_local_admin" 
                            id="topnav_my_teams" 
                            title="My Teams"
                            color="primary" 
                            to="/app/teams/" 
                            append-icon="fa fa-user-group" 
                        />
                        <VDivider class="nav-divider"/>
                        <VListItem 
                            id="topnav_coi_checker"
                            color="primary"
                            to="/app/coi_checker/"
                            append-icon="fa fa-arrows-left-right-to-line"
                            title="Conflicts of Interest"
                        />
                        <VDivider class="nav-divider"/>
                    </div>
                    <VListItem id="topnav_signout" href="/logout/" append-icon="fa fa-sign-out" title="Sign Out"/>
                </VList>
            </VMenu>
        </div>
    </nav>
</template>

<script>

import { mapState } from 'vuex'

export default {
    name: "Navbar",
    computed: mapState([
    // map this.count to store.state.count 
        'searchmodules', 'selected_searchmodule', 'user_profile'
    ]),
    data: function() {return({
        menu_open: 0
    })},
    methods: {
        check_active_source: function(searchmodule) {
            if (typeof(this.selected_searchmodule) !== 'undefined' && this.selected_searchmodule.pk === searchmodule.pk) {
                return 'searchmodule-active'
            }
        }
    }
}   
</script>

<style scoped>
    nav{
        color:white;
        background-color: var(--secondary_color);
        z-index:10;
    }
    .container-fluid {
        position:sticky;
        max-width:1920px;
        width:100vw;
    }
    .navbar-brand {
        mask: url('../assets/img/gc_logo.svg');
        -webkit-mask: url('../assets/img/gc_logo.svg');
        mask-size: fit;
        -webkit-mask-size: fit;
        mask-repeat:no-repeat;
        -webkit-mask-repeat:no-repeat;
        background-color:#f7f7f7;
        width:160px;
        height:30px;
    }
    .navbar-brand:hover {
        background-color:var(--orange_highlight)!important;
    }
    .searchmodule-active {
        color:var(--orange_highlight);
    }
    .user-avatar {
        width:30px;
        height:30px;
        margin-right:.5rem;
        display:inline-block;
        border-radius:50%;
        background: grey;
        color:white;
    }
    .avatar-link {
        text-decoration: none;
        color:white;
    }
    .avatar-link:hover {
        color:var(--orange_highlight);
    }
    .active {
        color:var(--orange_highlight);
    }
    .nav-divider {
        margin:8px 0px;
    }
</style>