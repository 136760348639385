<template>
    <v-list id="works" bg-color="transparent" :lines="false" density="compact">
      <!-- Always Visible Items -->
      <template v-for="(work, index) in pubs.slice(0, show.max_works)" :key="index"  v-if="pubs">
        <SubcollectionWork :work="work" />
      </template>

      <!-- Smoothly Expanding Items -->
      <VExpandTransition>
        <div v-if="pubs && expanded">
          <template v-for="(work, index) in pubs.slice(show.max_works)" :key="'extra-' + index">
            <SubcollectionWork :work="work" />
          </template>
        </div>
      </VExpandTransition>

      <!-- Toggle Button Always at the End -->
      <div class="d-flex justify-content-between pt-2">
        <LabelBox :item="item" />
        <a class="expert-action-container grey-text" role="button" v-if="pubs?.length > show.max_works" variant="text" density="comfortable" @click="expanded=!expanded">
            {{ expanded ? "- Show Less" : "+ Show More" }}
        </a>
      </div>
    </v-list>
</template>

<script>
import LabelBox from './LabelBox.vue'
import SubcollectionWork from './Work/SubcollectionWork.vue'

export default {
    name: 'CollectionItems',
    components: {LabelBox, SubcollectionWork},
    props: ['item', 'pubs', 'orcid_details', 'scholar_details', 'in_popup'],
    data: function() {return({
        expanded: 0,
        show: {
            description: 0,
            max_works: 2,
        }
    })},
}
</script>

<style>
#works {
    width:var(--resultlist_width);
}

</style>