<template>
  <div class="home" 
        :class='{
      "bg-at": $store.state.user_profile.organisation.name == "AcademicTransfer",
      "bg-gc": $store.state.user_profile.organisation.name != "AcademicTransfer",
    }'
    :style="getColorStyling()"
  >
    <div id="app-content">
      <navbar></navbar>
      <div id="page-content" class="p-4">
        <h3>Your Filters</h3> 
        <p>
          On this page you can create and save filters. 
          You create as many filters as you want. 
          Only one filter can be set to active, which is then used as the default filter for your searches on Global Campus.
        </p>
        <div id="filter-preset-list" class="d-inline-block col-12 col-md-5 pe-md-4">
        <VRow justify="between" align-content="end">
          <VCol>
            <VTextField
              :single-line="true"
              v-model="filter_querystring"
              @update:modelValue="fetch_presets()"
              outlined
              bg-color="white"
              density="compact"
              prepend-inner-icon="fa fa-search"
              label="Filter Preset by name"
            >
            </VTextField>
          </VCol>
          <VCol>
          <Pagination :item_count="filter_preset_count" :page_size="10" @load_page="fetch_presets($event)"/>
          </VCol>
        </VRow>
        <div class="d-flex flex-row filter-preset-item justify-content-between align-items-stretch"
                :key="preset_index"
                v-for="(filter_preset, preset_index) in shown_filter_presets">
            <div :class="selected_preset==filter_preset?'active':''"
                  class="preset-title d-flex flex-row w-100 justify-content-between align-items-center" @click="select_preset(filter_preset)">
              <span class="d-flex flex-row align-items-center flex-grow-1">
                  <input id="edit-preset-name" class="edit-preset-name w-100" v-if="selected_preset==filter_preset" v-model="filter_preset.name"/>
                  <span v-else class="preset-name">{{filter_preset.name}}</span>
              </span>
              <span v-if="filter_preset.uuid">
                  <div class="delete-btn ms-2" @click.stop="delete_preset(filter_preset)">
                    <i class="fa fa-trash"></i>
                  </div>
              </span>
          </div>
          <div class="filter-status" id="preset_active" @click="activate_preset(null)" v-if="user_profile.default_filter_preset==filter_preset.uuid && filter_preset.uuid">Activated</div>
          <div class="filter-status" :id="'preset_inactive'+preset_index" @click="activate_preset(filter_preset.uuid)" v-if="user_profile.default_filter_preset!==filter_preset.uuid && filter_preset.uuid">Not active</div>
          <div class="filter-status" @click="create_preset('preset_inactive'+preset_index)" v-if="!filter_preset.uuid">Save</div>
        </div>
          <button id="add_preset" class="btn-primary" @click="show.search_module_selector=1-show.search_module_selector">
            <i class="fa-solid fa-add"></i> Add a Filter
          </button>
          <SearchModuleSelector @close="show.search_module_selector=0" @type_selected="add_preset_with_type" v-if="show.search_module_selector"/>
        </div>
        <div v-if="selected_preset.name" class="filter-presets d-inline-block col-12 col-md-7 mt-md-0 mt-4">
          <Filters :context="'PresetFilters'"/>
          <button class="btn-primary float-end mt-2" id="update_preset_button" @click="create_preset('update_preset_button')" v-if="!selected_preset?.uuid">
            <i class="fas fa-save"></i> Save preset
          </button>
          <button class="btn-primary float-end mt-2" id="update_preset_button" @click="update_preset('update_preset_button')" v-else>
            <i class="fas fa-save"></i> Update preset
          </button>
        </div>
      </div>
    </div>
    <div id="tooltip_container">
      <tooltip :key="idx" :idx="idx" :tooltip="tooltip" v-for="(tooltip, idx) in $store.state.tooltips"></tooltip>
    </div>
  </div>
</template>

<script>
//dependencies
import navbar from "../components/Navbar.vue";
import shared from "../components/shared";
import Pagination from "../components/Pagination.vue";
import Filters from "../components/Sidebar/Filters/Filters.vue";
import { mapState } from "vuex";
import SearchModuleSelector from '../components/PresetSearchModuleSelector.vue';
import tooltip from '../components/Tooltip.vue'


export default {
  name: "Filter Presets",
  components: { navbar, Filters, SearchModuleSelector, tooltip, Pagination},
  computed: {
    ...mapState(["selected_searchmodule", "searchmodules", "user_profile"]),
    colorMap: shared.colorMap,
    shown_filter_presets: function() {
      if (!this.loading) return this.filter_presets
      return []
    }
},
  data: function () {
    return {
      loading: false,
      filter_presets: [],
      filter_querystring: '',
      filter_preset_count: 0,
      selected_preset: {uuid:''},
      show: {search_module_selector:0}
     };
  },
  methods: {
    getColorStyling: function () {
      return shared.colorMap(this.user_profile.organisation.name);
    },
    fetch_presets: async function(page_number) {
      console.log('fetching presets', page_number)
      var params = {
              page: page_number??1,
              page_size: 10,
              no_shared: 0,
      }
      console.log('checkinf for name!')
      if (this.filter_querystring && this.filter_querystring.length) {
        params['name'] = this.filter_querystring
      }
      console.log('params is like', params)
      const filter_preset_response = await this.$axiosQ.jumpQueue('gcapi', {
            method: 'get', 
            url: '/api/filter_presets/',
            params: params
        })
      this.filter_presets = filter_preset_response.data.results
      this.filter_preset_count = filter_preset_response.data.count
    },
    activate_preset: function(uuid) {
      /*  Activate a preset for the current user. 
      
      Set the active filter preset for the user to the filter preset with the given 
      uuid. If uuid is null, deactivate the filter preset for the user.
      */
      this.user_profile.default_filter_preset = uuid
      let url_extension = uuid ? uuid + "/activate/" : "deactivate/"
      this.$axiosQ.jumpQueue('gcapi', {
        method: 'put', 
        url: "/api/filter_presets/" + url_extension, 
      })
      .then(response => console.log(response))
    },
    select_preset: function (preset) {
      /* Select a filter preset to create/update. */
      if(this.selected_preset == preset) return false;
      this.selected_preset = {}
      let _this = this
      console.log('loading preset:', preset)
      this.$store.dispatch('load_filter_preset', preset).then(function() {
        console.log('setting selected filter preset to ' + preset.uuid)
        _this.selected_preset = preset;
      })
    },
    create_preset: function(anchor_id) {
      /* Create the selected preset in the database */
      let _this = this;
      this.selected_preset['filter_data']['filters'] = this.$store.getters.getPermittedFilters
      this.selected_preset['conflict_of_interest'] = this.$store.state.search.conflict_of_interest
      this.$axiosQ.jumpQueue('gcapi', {
        method: 'post', 
        url:"/api/filter_presets/",
        data: this.selected_preset})
      .then((response) => {
        console.log(response.data.message)
        _this.selected_preset['uuid'] = response.data.uuid
        var tooltip_object = {
            anchor_element_id: anchor_id, 
            message: "Filter Preset created successfully",
            style: "success",
            placement: "bottom"
        }
        _this.$store.commit('ADD_TOOLTIP', tooltip_object)
      });
    },
    update_preset: function(anchor_id) {
      /* Update the selected preset in the database. */
      console.log(anchor_id)
      let _this = this;
      this.selected_preset['filter_data']['filters'] = this.$store.getters.getPermittedFilters
      this.selected_preset['conflict_of_interest'] = this.$store.state.search.conflict_of_interest
      this.$axiosQ.jumpQueue('gcapi', {
        method: 'put', 
        url: '/api/filter_presets/' + this.selected_preset.uuid + '/',
        data: this.selected_preset})
      .then((response) => {
        var tooltip_object = {
            anchor_element_id: anchor_id, 
            message: "Filter Preset updated successfully",
            style: "success",
            placement: "auto"
        }
        _this.$store.commit('ADD_TOOLTIP', tooltip_object)
      });
    },
    delete_preset: function(delete_preset) {
      /* Delete the preset from the database. */
      if(confirm('Are you sure? This will permanently delete the preset called ' + delete_preset.name)) {
        this.$axiosQ.jumpQueue('gcapi', {
          method: 'delete', 
          url: '/api/filter_presets/'+delete_preset.uuid+'/'})
        .then(response => {
          console.log(response.data)
          this.filter_presets = _.reject(this.filter_presets, user_preset => {
            return user_preset.uuid == delete_preset.uuid
          });
        })
      }
    },
    add_preset_with_type: function(type) {
      let created_preset = {
        name: "Please give this filter a name",
        search_module: type.pk,
        unsaved: true,
        filter_data: {
          filters: []
        }
      }
      this.filter_presets.push(created_preset) 
      this.select_preset(created_preset)
      this.show.search_module_selector = 0;
    }
  },
  created: function() {
    this.$store.commit('SET_USER',JSON.parse(document.getElementById('user-data').text));
  },
  mounted: async function () {
    this.loading = true;
    let searchmodules = JSON.parse(document.getElementById("search-modules-data").text)
    this.$store.commit("SET_SEARCHMODULES", searchmodules);
    this.fetch_presets()
    this.loading = false
  }
};
</script>

<style scoped>
.no-decoration {
  text-decoration: none;
}
.query-link {
  color: initial;
}
.hover-orange:hover {
  color: var(--orange_highlight);
}

#collections-index {
  margin: 0 auto;
  max-width: 1920px;
  width: 100vw;
}
.filter-presets {
  flex-grow:3;
}
.filter-preset-item {
  margin: 0 0 0.75rem 0;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  color:var(--primary_color);
  box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
  background: white;
  width: 100%;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  border-radius: var(--ms-radius);
  min-height:40px;
}
.preset-title:hover {
  background: var(--orange_highlight);
  border-radius:  var(--ms-radius) 0 0 var(--ms-radius);
  color:white;
  cursor: pointer;
}
.filter-status:hover {
  background: var(--orange_highlight);
  border-radius: 0 var(--ms-radius) var(--ms-radius) 0 ;
  color:white;
  cursor:pointer;
}
.active {
  background: var(--primary_color);
  color:white;
}
.delete-btn:hover {
  color:red;
  }
.edit-preset-name {
  padding: 2px 8px;
  width:90%;
}
.preset-name {
  padding:4px 8px;
}
#filter-preset-list {
  vertical-align:top;
}
.filter-status {
  flex-grow:1;
  display:flex;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  align-items:center;
  justify-content:space-around;
  background: white;
  border-radius:0 var(--ms-radius) var(--ms-radius) 0;
  min-width:120px;
  border-left: solid 3px var(--background_color);
}
.preset-title {
  padding: .25rem .5rem;
}
#preset_active {
  background: var(--primary_color);
  color:white;
}
</style>