<template>
    <div v-if="$store.state.user_profile.organisation.nl_language" class="mt-1 mb-3">
        <label class="checkbox-inline d-flex justify-content-between align-items-center" for="DutchCheckbox">
            <div>
                <input id="DutchCheckbox" v-model="language_nl" value="1" type="checkbox"/>
                <span class="d-none d-xxl-inline-block ms-1">Likely</span> Dutch speaker 
            </div>
            <span class="grey-text"><i class="fa fa-info-circle" title="Using this checkbox includes authors who have either:
    1) published in the Dutch language,
    2) published whilst affiliated to an NL/BE institution 
    3) are currently affiliated to an NL/BE institution."></i>
            </span>
        </label>
    </div>
</template>

<script>
export default {
    name: "Dutch language filter",
    computed: {
        language_nl: {
            get: function(){
                return Object.keys(this.$store.state.filters).includes('language');
            },
            set: function(value) {
                this.$store.commit('TOGGLE_LANGUAGE_NL')
            }
        },
    }
}
</script>

<style>

</style>