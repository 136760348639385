<template>
<VCard title="Reset your password" class="pa-5" rounded variant="elevated">
    <VTextField
        v-model="email_address"
        id="email_address_password_reset"
        autocomplete="username"
        class="my-2"
        label="Email Address of your account"
    ></VTextField>     
    <VBtn
        id="email_address_password_reset_submit"
        type="button"
        :loading="loading"
        @click="post_request_new_password"
        class="entrance-submit"
        variant="elevated"
        block
    >
        Submit
    </VBtn>
</VCard>
</template>

<script>
export default {
    name: 'PasswordForgottenForm',
    data: function() {return({
        email_address: '',
        loading: false,
    })},
    methods: {
        post_request_new_password: async function() {
            this.loading = true
            this.$axiosQ.jumpQueue('gcallauth', {
                method: 'post', 
                url: "/auth/password/request",
                data: {
                    "email": this.email_address
                }
            }).then(response => {
                console.log(response)
                this.$emit('formThrowsError', {errors: [{code: "reset_email_sent"}]})
            })
            .catch(axios_error => {
                console.log('failed_response', axios_error)
                this.$emit('formThrowsError', axios_error.response.data)
            }).finally(() => {
                this.loading = false  
            })
        },    
    }
}
</script>

<style>

</style>