<template>
  <div class="home" 
      :class='{
      "bg-at": $store.state.user_profile.organisation.name == "AcademicTransfer",
      "bg-gc": $store.state.user_profile.organisation.name != "AcademicTransfer",
    }'
    :style="getColorStyling()">
    <navbar></navbar>
    <div id="app-content" class="d-flex flex-column flex-grow-1">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h2 class="py-4">Checking for conflicts of interest</h2>
            <p>On this page you can do batchwise comparisons between the Conflict of Interest fields of <a target="_blank" href="/app/filters/">Filters</a> and <a target="_blank" href="/app/collections/">Collections</a>.</p>
          </div>
          <div class="col-6 col-xs-12">
            <div for="institution_type" class="sidebar-title">Check these authors:</div>
            <Multiselect
                mode="single" label="name" valueProp="uuid" :searchable="true" placeholder="The authors will be placed on rows" 
                :attrs='{"autocomplete": "one-time-code"}' 
                @change="show.results = 0"
                v-model="check"
                :object="true"
                id="check_uuid_multiselect"
                :closeOnSelect="true"
                :closeOnDeselect="true"
                noOptionsText="Type to search Filter Presets and Collections."
                :delay="150" 
                :min-chars="3"
                :options="query_author_lists"
                :class="{'active' : check?.uuid}"
            >
              <template v-slot:option="{ option }">
                <div class="d-flex justify-items-between">
                    <span>{{ option.name }} ({{option.type}})</span>
                </div>
              </template>
            </Multiselect>
            <div for="institution_type" class="sidebar-title">Against these authors:</div>
            <Multiselect
                mode="single" 
                label="name"
                valueProp="uuid" 
                :searchable="true" placeholder="The authors will be placed on columns" 
                :attrs='{"autocomplete": "one-time-code"}' 
                noOptionsText="Type to search Filter Presets and Collections."
                @change="show.results = 0"
                :delay="150" 
                :min-chars="3"
                v-model="against"
                :object="true"
                id="against_uuid_multiselect" 
                :closeOnSelect="true" 
                :closeOnDeselect="true"
                :options="query_author_lists" :class="{'active' : against?.uuid}"
            >
              <template v-slot:option="{ option }">
                <div class="d-flex justify-items-between">
                    <span>{{ option.name }} ({{option.type}})</span>
                </div>
              </template>
            </Multiselect>
            <div>
              <div class="sidebar-title">Since:</div>
              <input id="pub-date" class="multiselect d-block datepicker-custom" v-model="$store.state.coi_store.since_year" min="2000" max="2024" type="number" step="1"/>
            </div>
          </div>
          <div class="col-6 col-xs-12">
            <div class="mt-4">
                <div class="d-flex flex-column">
                  <div class="slider" role="button" 
                      :class="active_methods.includes('coauthors')?'active':'inactive'"
                      @click="toggle_method('coauthors')">
                      <h5 class="slider-header me-2">Co-authorships</h5>
                      <div class="slider-space">
                          <span class="slider-toggle"></span>
                      </div>
                  </div>
                  <div v-if="active_methods.includes('coauthors')">
                    <Multiselect
                        mode="tags" label="name" valueProp="id" :searchable="true" placeholder="Only these publication types" 
                        :attrs='{"autocomplete": "one-time-code"}'
                        v-model="crossref_types" :object="false" id="source_type_multiselect" :closeOnSelect="false" :closeOnDeselect="true"
                        :options="crossref_options" class='active mt-2'
                    >
                    </Multiselect>
                    <label class="checkbox-inline d-flex align-items-center mt-2" for="author_position_toggle">
                          <input id="author_position_toggle" class="me-1" v-model="author_position" type="checkbox"/>
                          Check only first 2 and last 2 authors 
                    </label>
                  </div>
                  <div class="slider mt-4" role="button" 
                      :class="active_methods.includes('coaffiliated')?'active':'inactive'"
                      @click="toggle_method('coaffiliated')">
                      <h5 class="slider-header me-2 ">Historical Affiliations</h5>
                      <div class="slider-space">
                          <span class="slider-toggle"></span>
                      </div>
                  </div>
                </div>
            </div>
          </div>
          <div class="col-12">
            <div class="my-4">
              <span class="btn-primary me-2" id="check_conflicts_btn" @click="get_coi_information()">Check</span>
              <span class="btn-primary" v-if="show.results" @click="excel_export()"> <i class="fa fa-file-excel"></i> Excel Export</span>
            </div>
          </div>
        </div>
      <div v-if="show.loading" class="row">
          <Spinner/>
      </div>
      </div>
      <!-- Result area -->
      <div class="container-fluid mw-100">
        <div class="row" v-if="show.results">
          <hr/>
          <table id="coi_results" class="my-4">
            <thead>
              <th></th>
              <th class="px-1 text-center" 
                  :key="'check'+header_expert.identifier" 
                  v-for="header_expert in against.records"
                  @mouseenter="set_hover(false, header_expert.identifier)"
                  @mouseleave="set_hover(false,false)"
                  :class="{'hover_column': this.hover.column==header_expert.identifier}"
                >
                  {{header_expert.fields.display_name}}
                </th>
            </thead>
            <tbody>
              <tr class="text-center"
                  :key="'against'+row_expert.identifier"
                  v-for="row_expert in check.records"
                  @mouseenter="set_hover(row_expert.identifier, false)"
                  @mouseleave="set_hover(false,false)"
                  :class="{'hover_row': this.hover.row==row_expert.identifier}"
                >
                <td>{{row_expert.fields.display_name}}</td>
                <td class="coi_cell"
                    :key="'intersect'+row_expert+'-'+column_expert"
                    :class="{'hover_column': this.hover.column==column_expert.identifier}"
                    v-for="column_expert in against.records" 
                    @mouseenter="set_hover(row_expert.identifier, column_expert.identifier)"
                    @mouseleave="set_hover(false,false)"
                    @click="this.$store.dispatch('coi_store/get_coi_information', {check: [row_expert], against: [column_expert]})"
                  >
                  <div v-if="row_expert.identifier == column_expert.identifier">
                    <span class="badge bg-warning">Overlap</span>
                  </div>
                  <div v-else>
                    <span class="badge bg-info me-2" v-if="coauthorshipsMap[`${row_expert.identifier}-${column_expert.identifier}`].length">
                      {{coauthorshipsMap[`${row_expert.identifier}-${column_expert.identifier}`].length}}<span v-if="coauthorshipsMap[`${row_expert.identifier}-${column_expert.identifier}`].length == 5">+</span>
                      Publications
                    </span>
                    <span class="badge bg-success me-2" v-if="coaffiliationsMap[`${row_expert.identifier}-${column_expert.identifier}`].length">
                      {{coaffiliationsMap[`${row_expert.identifier}-${column_expert.identifier}`].length}} Institutions
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <ModalContainer/>
    </div>
  </div>
</template>

<script>
//dependencies
import navbar from '../components/Navbar.vue'
import shared from '../components/shared'
import Spinner from '../components/Spinner.vue';
import ModalContainer from '../components/Modals/ModalContainer.vue'
import * as XLSX from 'xlsx'

import {mapState} from 'vuex'

export default {
  name: 'CoiChecker',
  components: {navbar, ModalContainer, Spinner},
  computed: {
    ...mapState(['user_profile', 'modals']),
    active_methods: {
      get() {return this.$store.state.coi_store.active_methods},
      set(value) {this.$store.state.coi_store.active_methods = value}
    },
    colorMap: shared.colorMap,
      // build a map of all co-authorships between check/against authors
    coauthorshipsMap() {
      let _this = this; 
      const map = {};
      this.against.records.forEach(row => {
        _this.check.records.forEach(column => {
          var coauthor = _.find(this.$store.state.coi_relationships[column.identifier]?.conflicts?.coauthors?.evidence, (coauthor) => {
            return coauthor.id == row.identifier
          })
          if (coauthor) {
            map[`${column.identifier}-${row.identifier}`] = coauthor.works
          }
        else {
          map[`${column.identifier}-${row.identifier}`] = []
        }
        });
      });
      return map;
    },
    // build a map of all affiliation relationships between check/against authors
    coaffiliationsMap() {
      let _this = this; 
      const map = {};
      this.against.records.forEach(row => {
        _this.check.records.forEach(column => {
          var coaff_author = _.find(this.$store.state.coi_relationships[column.identifier]?.conflicts?.coaffiliated?.evidence, (coaff) => {
              return coaff.id == row.identifier
          })
          map[`${column.identifier}-${row.identifier}`] = Object.keys(_.groupBy(coaff_author?.['affiliation_history'], 'institution_id'))
        });
      });
      return map;
    },
    check_ids: function() {
      if (!this.check?.uuid) return []
      return this.check.records.map(item => {return item.identifier})
    },
    against_ids: function() {
      if (!this.against?.uuid) return []
      return this.against.records.map(item => {return item.identifier})
    },
  },
  created: function() {
    this.$store.commit('SET_USER',JSON.parse(document.getElementById('user-data').text));
    let search_modules = JSON.parse(document.getElementById('search-modules-data').text)
    this.$store.commit('SET_SEARCHMODULES', search_modules)
  },
  data: function() {return(
    {
      check: {},
      against: {},
      author_affs: [],
      author_position: 0,
      institutions: {},
      active_queries:[],
      show: {
        loading: 0,
        results: 0,
      },
      crossref_types: [
        "journal-article"
      ],
      crossref_options: [
        {name: "Journal Articles", id: "journal-article"},
        {name: "Proceedings", id: "proceedings"},
        {name: "Preprints and posted content", id: "posted-content"},
        {name: "Database", id: "database"},
      ],
      hover: {
        row: false,
        column:false,
      },
      user: {
        searches: []
    }
  })},
  methods: {
      toggle_method(toggle_method) {
        this.active_methods = _.xor(this.active_methods, [toggle_method])
      },
      // This function combines two sources of author lists:
      // 1. preset filters
      // 2. Searches that are queried with the user input (by name).
      // it returns a list of objects: [{name: '', uuid: uuid, records: [] }]}
      // e.g. [{name: 'collection_1', uuid: '1234-...-3456', records: [Author1, Author2]}]
      query_author_lists: async function(q) {
        if (q) {
          var filter_preset_response = await this.$axiosQ.jumpQueue('gcapi', {
            method: 'get', 
            url: "/api/filter_presets/?name=" + shared.latinize(q)
          })
          console.log('found filter presets:', filter_preset_response)
          const filtered_presets = _.map(filter_preset_response.data.results, (preset) => {
            // some presets have an old format, where the records are OpenAlex Suggestions nested in check:
            if (preset.conflict_of_interest.check) {
              return {
                name: preset.name,
                uuid: preset.uuid,
                type: "Filter",
                records: _.map(preset.conflict_of_interest.check, shared.make_author_from_oa_suggestion)
              }
            }
            return {
              name: preset.name,
              uuid: preset.uuid,
              type: "Filter",
              records: preset.conflict_of_interest.records
            }           
          })
          var search_response = await this.$axiosQ.jumpQueue('gcapi', {
            method: 'get', 
            url: "/api/searches/?name=" + shared.latinize(q)
          })
          const coi_records = _.map(search_response.data.results, (search) => {
            return {name: search.name, type: "Collection", uuid: search.uuid, records: search.conflict_of_interest?.records}
          })
          const response_list = [...filtered_presets, ...coi_records]
          return response_list
        }
        return []
      },
      set_hover: function(row_expert_id, column_expert_id) {
        this.hover.row = row_expert_id;
        this.hover.column = column_expert_id;
      },
      show_coi_details: function(row_expert, column_expert) {
        this.$store.dispatch('coi_store/get_coi_information', {check: [row_expert], against: column_expert})
      },
      get_coi_information: async function() {
        if(!this.check?.uuid || !this.against?.uuid) {
          alert('Both the column and row fields need to be present.')
          return false
        }
        if(!this.active_methods.length) {
          alert('You need to select at least one method of conflict of interest check.')
          return false
        }
        this.show.results = 0
        this.show.loading = 1
        this.$store.dispatch('check_conflicts_of_interest', {check: this.check_ids, against:this.against_ids}).then(() => {
          this.show.loading = 0
          this.show.results = 1
        })
      },
      getColorStyling: function() {
        return shared.colorMap(this.user_profile.organisation.name)
      },
      excel_export: function() {
        let _this = this;
        var workbook = XLSX.utils.book_new()
        try {
          const ws = _this.create_worksheet()
          XLSX.utils.book_append_sheet(workbook , ws,  'conflicts');
        }
        catch(err) {
          console.log('Unable to generate output for type:' + err)
        }
        XLSX.writeFile(workbook, "GlobalCampus CoI details "+ new Date().toISOString() + ".xlsx");
      },
      create_worksheet:function() {
        let _this = this;
        let coi_details = []
        // for every row, we add something to our JSON dictionary of conflicts.
        _.map(_this.check.records, function(row_expert, key) {
            let row_conflicts = {}
            // first, the header column
            row_conflicts['Author_name'] = row_expert.fields.display_name,
            // then, for every column author (in the Against list),
            // we add Pub and Inst data to the cells.
            _.each(_this.against.records, function(column_expert) {
              let coi_description = ''
              // if copubs, add count
              if(row_expert.identifier == column_expert.identifier) {
                coi_description += "Overlap"
              }
              else {
                var coauthorship = _this.coauthorshipsMap[`${row_expert.identifier}-${column_expert.identifier}`]
                if(coauthorship.length) {
                  coi_description += coauthorship.length + ' Publications: \r\n';
                  coauthorship.forEach(publication => {
                    coi_description += publication +'\r\n'
                  })
                }
                // if co-institutions, add count:
                let institution_intersection = _this.coaffiliationsMap[`${row_expert.identifier}-${column_expert.identifier}`]
                if(institution_intersection.length) {
                  coi_description += institution_intersection.length + ' Institutions: \r\n';
                  coi_description += institution_intersection.join('\r\n')
                }
              }
              row_conflicts[column_expert.fields.display_name] = coi_description
            })
            coi_details.push(row_conflicts)
        });
        const ws = XLSX.utils.json_to_sheet(coi_details);
        return ws
      }
    },
}
</script>

<style scoped>
  .home {
    height: 100vh;
    background-color:var(--background-color);
    width:100vw;
    overflow-y: hidden;
  }
  .no-decoration {
    text-decoration:none;
  }
  .query-link {
    color:initial;
  }
  .hover-orange:hover  {
    color:var(--orange_highlight);
  }
  #app-content{
      display:flex;
      width:100vw;
      min-width:800px;
      margin:auto;
      height:calc(100vh - 40px);
      overflow-y:auto;
  }
  #collections-index {
    margin: 0 auto;
    max-width: 1920px;
    width: 100vw;
  }
  a, .active {
    color: var(--primary_color);
  }
  #coi_results {
    table-layout:fixed;
    background-color:transparent!important;
  }
  .coi_cell:hover {
    cursor:pointer;
  }
  .hover_column, .hover_row {
    background-color: var(--primary_color_30);
  }

  .slider {
    justify-content:flex-start;
    align-items:baseline;
  }
  .slider.active .slider-header{ 
      color: var(--primary_color)!important;
  }
  .slider.active .slider-toggle {
    margin-left:14px;
    background: var(--primary_color);
  }
  .slider.inactive .slider-toggle {
    margin-left:1px;
    background:white;
  }
</style>