<template>
<div id="entrance-background" class="d-flex vw-100 vh-100">
    <div id="entrance-container">
        <VCard v-if="messages.length" class="alert-card mb-4">
            <VAlert :key="'error'+idx" v-for="(message, idx) in messages"
                    :max-width="'500px'"
                    :closable="true"
                    :title="message.title"
                    :color="message.color"
                    @click:close="close_message(idx)"
                    variant="tonal"
                >
                {{message.details}}
            </VAlert>
        </VCard>
        <LoginForm 
            v-if="show=='LoginForm'"
            class="login entrance-form"
            @forgotPassword="show='PasswordForgottenForm'"
            @formThrowsError="parseFormError"
        />
        <PasswordForgottenForm 
            v-if="show=='PasswordForgottenForm'"
            class="password_forgotten entrance-form"
            @formThrowsError="parseFormError"
        />
        <UpdatePassword 
            v-if="show=='PasswordResetForm'" 
            class="password_reset entrance-form"
            :password_reset_key="$route.params?.key??false" 
            @formThrowsError="parseFormError"
        />
    </div>
</div>
</template>

<script>
import UpdatePassword from '../components/Account/UpdatePassword.vue'
import LoginForm from '../components/Entrance/LoginForm.vue'
import PasswordForgottenForm from '../components/Entrance/PasswordForgottenForm.vue'

export default {
    name: "Entrance",
    components: {LoginForm, PasswordForgottenForm, UpdatePassword},
    created:function() {
        // Process route parameters
        if(this.$route.query?.reason??false) {
            this.parseRouteError()
        }
        else if (this.$route.name == "ResetPassword") {
            this.show="PasswordResetForm"
        }
    },
    data: function() {return({
        errors: [],
        show: 'LoginForm',
        messages: [],
        message_templates: {
            'authorization_expired': {
                title:   "You need to refresh your authorization.",
                color:   "danger",
                details: `You logged in succesfully with ${this.$route.query.provider}, 
                           but your account at Global Campus is authorized through ${this.$route.query?.organisation??'a third party'}.
                           To refresh your access, please login again using ${this.$route.query?.organisation}.`
            },
            'missing_license': {
                title: "No license",
                color:   "danger",
                details: "Your Global Campus is no longer active, or no license is attached to it. " +
                           "Please contact support at support@globalcampus.ai."
            },
            'social_account_failure': {
                title: "Unable to login",
                color:   "danger",
                details: "Global Campus could not complete the login request. Please reach out to support@globalcampus.ai."
            },
            'reset_email_sent': {
                title: "Password Reset email sent",
                color:   "info",
                details: "If a user with this email address exists, a password reset email has been sent to the email address you provided."
            },
            'password_changed': {
                title: "You have successfully changed your password.",
                color: "success",
                details: "Please login using your newly updated credentials."
            },
            'username_password_mismatch': {
                title: "Incorrect username and/or password",
                color:   "danger",
                details: "The credentials you supplied are not correct. Please note that we use usernames, and not email addresses."
            },
            'signup_not_allowed': {
                title: "Signup not allowed",
                color:   "danger",
                details: `You can only login with ${this.$route.query.provider} to existing accounts.
                            Because there is no existing account attached to your ${this.$route.query.provider}, you are seeing this error.
                            Please reach out to access@globalcampus.ai for help.`
            }
        },
    })},
    methods: {
        close_message(index) {
            this.messages.splice(index, 1)
        },
        parseFormError(error_response) {
            const first_error = error_response.errors[0]
            console.log('We received the following message from the form:', first_error?.code??'no error')
            if(this.message_templates[first_error.code]) {
                this.messages.push(this.message_templates[first_error.code])
            }
            else {
                this.messages.push({
                    title: first_error.code,
                    details: first_error.message
                })
            }
        },
        parseRouteError() { 
            this.messages.push(
                this.message_templates[this.$route.query?.reason]
            )
            this.$router.push('/accounts/login/')
        }
    }
}
</script>

<style>
    #entrance-background {
        background: url('../assets/img/bg-login.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        position:fixed;
        overflow-y:auto;
    }
    #entrance-container {
        position: absolute;
        left: 10%;
        top: 30%;
        width:400px;
    }
    .entrance-form, .alert-card {
        box-shadow: 0 0 23px 5px #888;
    }
    .entrance-submit {
        color:white!important;
        background-color: rgb(var(--v-theme-primary))!important;
    }
</style>