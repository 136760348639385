<template>
  <div id="filters">
    <div class="filter-row text-left">
        <VListItem v-if="$store.state.user_profile.default_filter_preset"><FilterPresetSelector /></VListItem>
        <VListItem v-if="filter_available('coi_authors')"><CoIFinder key="conflict_of_interest_finder"/></VListItem>
        <VListItem v-if="filter_available('email_confidence')"><EmailConfidenceFilter key="conflict_of_interest_finder"/></VListItem>
        <VListItem v-if="filter_available('authority_id')"><AuthorityIDFilter/></VListItem>
        <VExpansionPanels v-model="open_filter_groups" variant="accordion" :multiple="true" flat bg-color="transparent">
            <VExpansionPanel 
                v-for="(filter_group, group_index) in available_filter_groups" 
                :key="'filter_group'+group_index" 
                >
                <FilterGroupTitle
                    :id="'FilterGroupHeader'+filter_group.name"
                    :class="{'group-active': used_filter_groups.includes(filter_group.name)}"
                    :filtergroupname="filter_group.name" 
                />
                <VExpansionPanelText>
                    <component :key="'filter'+group_index+'.'+filter_index"
                        v-for="(filter,filter_index) in filter_group.filters" 
                        :is="filter.component()"
                        :field="filter.field"
                        v-bind="filter.props"
                    />
                </VExpansionPanelText>
            </VExpansionPanel>
            <!-- <component v-if="$store.state.user_profile.organisation.name != 'AcademicTransfer'" v-bind:is="$store.state.selected_searchmodule.frontend_result_component+'Options'"></component> -->
        </VExpansionPanels>       
    </div>
    <div class="collection-buttons">    
        <button v-if="context!=='PresetFilters'" id="filter_update" class="btn-collection w-100" title="Rerun/update search" @click="$store.dispatch('find_similar')"><i class="fa fa-refresh"></i> Update</button>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

import AuthorPositionFilter from './AuthorPositionFilter.vue'
import CountryFilter from './CountryFilter.vue'
import CoIFinder from '../CoIFinder.vue'
import SourceTypeFilter from './SourceTypeFilter.vue'
import DateRangeFilter from './DateRangeFilter.vue'
import DocumentTypeFilter from './DocumentTypeFilter.vue'
import DutchLanguageFilter from './DutchLanguageFilter.vue'
import RangeFilter from './RangeFilter.vue'
import StarthubsOptions from '../StartupsOptions.vue'
import FilterPresetSelector from './FilterPresetSelector.vue'
import FilterGroupTitle from './FilterGroupTitle.vue'
import FilterSlider from './FilterSlider.vue'
import InstitutionNameFilter from './InstitutionNameFilter.vue'
import InstitutionTypeFilter from './InstitutionTypeFilter.vue'
import AuthorityIDFilter from './AuthorityIDFilter.vue'
import TextFilter from './TextFilter.vue'
import EmailConfidenceFilter from './EmailConfidenceFilter.vue'

export default {

    name: "Filters",
    props: ["preset", "context"],
    components: {
        AuthorPositionFilter,
        CoIFinder,
        CountryFilter,
        DateRangeFilter,
        DocumentTypeFilter,
        DutchLanguageFilter,
        EmailConfidenceFilter,
        FilterPresetSelector,
        FilterGroupTitle,
        FilterSlider,
        InstitutionNameFilter,
        InstitutionTypeFilter,
        AuthorityIDFilter, 
        RangeFilter,
        SourceTypeFilter,
        StarthubsOptions,
        TextFilter,
    },
    computed: {
        ...mapState(['user_profile', 'selected_searchmodule']),
        // goes through all filter groups and keeps only groups with their available filters
        available_filter_groups() {
            const filter_groups_with_available_filters = []
            this.all_filter_groups.forEach(filter_group => {
                var available_filters_in_this_group = []
                filter_group.filters.forEach(filter => {
                    if (this.filter_available(filter.field)) {available_filters_in_this_group.push(filter)}
                })
                if (available_filters_in_this_group.length) {
                    filter_group.filters = available_filters_in_this_group
                    filter_groups_with_available_filters.push(filter_group)}
            })
            return filter_groups_with_available_filters
        },
        used_filter_groups() {
            const used_groups = []
            this.available_filter_groups.forEach(group => {
                for (const filter of group.filters) {
                    if (this.$store.state.filters[filter.field]) {
                        used_groups.push(group.name)
                        break;
                    }
                }
            })
            return used_groups
        }
    },
    data: function() {return{
        open_filter_groups: [],
        all_filter_groups: [
            {
                name: "Matched Works",
                filters: [
                    {field: "source_type", component: () => SourceTypeFilter},
                    {field: "publication_date", component: () => DateRangeFilter},
                    {field: "document_type", component: () =>  DocumentTypeFilter},
                    {field: "abstract", component:  () => TextFilter},
                ]
            },
            {
                name: "Affiliation",
                filters: [
                    {field: "institution.country_code", component: () =>  CountryFilter},
                    {field: "language", component: () =>  DutchLanguageFilter},
                    {field: "institution.type", component: () => InstitutionTypeFilter},
                    {field: "institution.id", component: () => InstitutionNameFilter},
                ]
            },
            {
                name: "Seniority",
                filters: [
                    {field: "author_position", component: () =>  AuthorPositionFilter},
                    {field: "works_count", component: () => RangeFilter, props:{
                        min:0,
                        max:750,
                        step:1,
                        include_numerical_inputs:true,
                        filter_name: "Total Work Count"
                    }},
                    {field: "h_index", component: () => RangeFilter, props: {
                        min:0,
                        max:150,
                        step:1,
                        include_numerical_inputs:true,
                        filter_name: "H-index"
                    }},
                    {field: "years_active", component: () => RangeFilter, props: {
                        min:0,
                        max:40,
                        step:1,
                        include_numerical_inputs:true,
                        filter_name: "Years Active"
                    }},
                ]
            },
        ]
    }},
    methods: {
        filter_available: function(field_name) {
            return this.$store.getters.getCurrentlyAvailableFilters.includes(field_name)
        },
    },
    mounted: function() {
        // Upon mounting, we open the filter groups that have been set.
        this.available_filter_groups.forEach((group, index) => {
            for (const filter of group.filters) {
                if (this.$store.state.filters[filter.field]) {
                    this.open_filter_groups.push(index)
                    break;
                }
            }
        })
    },
}
</script>

<style>
    .multiselect-clear, .multiselect-caret {
        z-index:4!important;
    }
    .multiselect-clear-icon:hover {
        background-color:var(--almost_black);
    }
    .exclude {
        color:var(--contrast_color);
    }
    .selectize-input {
        padding: 4px 8px!important;
    }
    .form-control {
        font-size:13px!important;
        padding: 4px 8px!important;
    }
    #co-author-check .ellipsis-text {
        max-width:125px;
    }
    #filters .multiselect {
        box-shadow: var(--box_shadow_emphasis);
    }
    #filters .active {
        color: var(--primary_color);
    }
    #filters .group-active {
        color: var(--primary_color)!important;
    }
    #filters .multiselect.active .multiselect-caret{
        background-color: var(--primary_color);
        transform:rotate(270deg)
    }
    #filters .text-greyed-out {
        color:#999;
    }
    #filters .multiselect-placeholder {
        font-size:80%;
        padding-right:0!important;
    }
    #filters .multiselect-tags {
        justify-content: flex-start;
        max-width:100%;
        overflow-x:hidden;
    }
    #filters .multiselect-tag {
        text-overflow:ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    .multiselect-dropdown {
        overflow-x:visible;
        z-index: 5!important;
        border: none!important;
        box-shadow:var(--box_shadow_emphasis);
    }
    .multiselect-placeholder {
        font-size: .8rem;
    }
    #filters .form-control:focus {
        border: var(--ms-border-width-active,var(--ms-border-width,1px)) solid var(--ms-border-color-active,var(--ms-border-color,#d1d5db));
        box-shadow: 0 0 0 var(--ms-ring-width,3px) var(--ms-ring-color,rgba(16,185,129,.188));
    } 
    .collection-buttons{
        border-top:1px solid var(--grey_divider);
    }
    .v-expansion-panel-text__wrapper {
        padding: .5rem 1.5rem;
    }
</style>

