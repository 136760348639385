<template>
    <div class="mb-4" title="Use only the first or last author of matching papers.">
        <div class="sidebar-title">First/Last author</div>
        <Multiselect
            :object="false"
            :valueField="'value'"
            id="author_position_select"
            :classes="{singleLabel: 'multiselect-single-label no-caret-padding'}"
            :class="{'active': (this.author_weight !== 'any' && this.author_weight !== null)}"
            :options="[
                {'label': 'Any Author', 'value': 'any'},
                {'label': 'First Author', 'value': 'first'},
                {'label': 'Last Author', 'value': 'last'},
            ]"
            v-model="author_weight"/>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: "Author Position Filter",
    computed: {...mapState(['co_authors', 'query_uuid', 'co_author_field', 'user_profile']),
        author_weight: {
            get: function(){
                return this.$store.state.filters['author_position']?this.$store.state.filters['author_position']['value']:'any'
            },
            set: function(value) {
                let filter = {
                    value: value,
                    type: 'author_position',
                    field: 'author_position'
                }
                this.$store.commit('SET_FILTER', filter)
            },
        }
    },
}
</script>

<style>

</style>