<template>
<VRow id="resultlist-topbar" class="p-3 d-flex align-items-center justify-content-between flex-grow-0">
    <VTabs color="primary">
        <VTab 
            append-icon="fa fa-list"
            :class="{active: $store.state.show.results == 'as_list'}"
            base-color="greytext"
            id="toggle_list" 
            title="Show List" @click="$store.state.show.results = 'as_list'">
                Show List
        </VTab>
        <VTab 
            append-icon="fa fa-diagram-project"
            :class="{active: $store.state.show.results == 'as_graph'}"
            base-color="greytext"
            id="toggle_network_graph" 
            title="Show network graph" @click="$store.state.show.results = 'as_graph'" 
            v-if="$store.state.selected_searchmodule.allowed_api_endpoints.includes('network_graph')">
                Show network graph
        </VTab>
        <VTab 
            append-icon="fa fa-fingerprint"
            :class="{active: $store.state.show.results == 'as_topics'}"
                        base-color="greytext"
            id="toggle_topics" 
            title="Show topics" @click="$store.state.show.results = 'as_topics'" 
            v-if="$store.state.selected_searchmodule.allowed_api_endpoints.includes('topics')">
                Show Topics
        </VTab>
    </VTabs>
    <div class="d-flex align-items-center">
        <SearchStats/>
        <ExcelExport v-if="$store.state.selected_searchmodule.allowed_api_endpoints.includes('use_collections')"
            :exporting_items="grouped_current_results"
            classes="btn-primary me-2"
            :icon="true"
            title="Export all results to Excel"
            label="Export to Excel">
        </ExcelExport>
        <VBtn rounded="lg" v-if="show.results=='as_list'" class="text-white" color="primary">
            <span class="me-1 animate-flip" :class="{'flip-y': is_active_sort_descending}"><i class="fa fa-sort-asc"></i></span>
            {{active_sort.name}}
            <VMenu activator="parent">
                <VList>
                    <VListItem
                        @click="set_sort(sort_option)"
                        v-for="(sort_option, index) in sortable"
                        :key="index"
                        :value="sort_option.name"
                    >
                        <VListItemTitle>
                            {{ sort_option.name }}
                        </VListItemTitle>
                    </VListItem>
                </VList>
            </VMenu>
        </VBtn>
    </div>
</VRow>
</template>

<script>
import ExcelExport from './ExcelExport.vue'
import SearchStats from './SearchStats.vue'
import { mapState } from 'vuex'

export default {
    name: 'ResultlistTopbar',
    components: {ExcelExport, SearchStats},
    computed: {
        ...mapState(["show", "active_sort"]),
        is_active_sort_descending() {
            return this.active_sort.order == 'desc'
        },
        grouped_current_results: function() {
            return _.groupBy(this.$store.getters.getCurrentResultList, function(item) {
                return item.record_type + 's'
            })
        }
    },
    data: function() {return({
    })},
    methods: {
        set_sort: function(sort_option) {
            this.$store.commit('SORT_RESULT_LIST', sort_option)
        },
        toggle_sort_dropdown: function() {
            if (this.show.dropdown == 'sort_options') {
                this.$store.commit('SET_DROPDOWN', null)
            }
            else {
                this.$store.commit('SET_DROPDOWN', 'sort_options')
            }
        }
    },
    mounted: function() {
        if (!_.map(this.sortable, 'field').includes(this.active_sort.field)) {
            this.$store.commit('SORT_RESULT_LIST', this.sortable[0])
        }
    },
    props: ['sortable'],
}
</script>

<style scoped>
.dropdown-menu {
    display:block;
    z-index:6
}
.text-white {
    color:white!important;
}
span svg {
    transition: transform ease var(--fast_anim);
}
#resultlist-topbar {
    top:0px;
    z-index:5;
}
</style>