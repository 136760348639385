<template>
    <VCard title="Please Sign In to Global Campus" class="pa-5" rounded variant="elevated">
        <form>
            <VTextField
                v-model="username"
                id="username_field"
                autocomplete="username"
                :rules="[required]"
                :readonly="loading"
                class="my-2"
                label="Username"
            ></VTextField>

            <VTextField
                v-model="password"
                id="password_field"
                :readonly="loading"
                :rules="[required]"
                autocomplete="current-password"
                label="Password"
                type="password"
                placeholder="Enter your password"
            ></VTextField>
            <VBtn
                :loading="loading"
                id="submit_password_login"
                type="submit"
                class="entrance-submit my-4"
                variant="elevated"
                @click.prevent="post_login"
                block
            >
                Sign In
            </VBtn>
            <VRow justify="space-between" class="mt-2">
                <VBtn id="forgot_password_btn"
                    @click="$emit('forgotPassword')"
                    variant="plain"
                    >
                    Forgot Password
                </VBtn>
                <VBtn id="request_access_btn" 
                    href="mailto:access@globalcampus.ai?subject=I would like access to Global Campus&body=Please describe how you would like to use Global Campus."
                    variant="plain"
                    >
                    Request access
                </VBtn>
            </VRow>
        </form>
        <VDivider />
        <form method="POST" id="sso_form" action="/api/allauth/browser/v1/auth/provider/redirect">
            <input type="hidden" :value="csrf_token" name="csrfmiddlewaretoken">
            <input type="hidden" name="provider" id="sso_provider" value="not_set_yet"/>
            <input type="hidden" name="callback_url" value="/app/"/>
            <input type="hidden" name="process" value="login"/>
        </form>
        <VContainer>
        <VRow justify="center">
            <VBtn type="button" id="orcid_sso_button" @click="open_idp_login('orcid')" class="my-4">
                <VIcon class="orcid me-1" icon="fa-brands fa-orcid" />
                Login through Orcid
            </VBtn>
        </VRow>
        <VRow justify="center" class="my-4" @click="show.sso=true" > 
            <VBtn v-show="!show.sso" id="query_idp_toggle" variant="elevated">
                <VIcon class="me-1" icon="fa fa-institution" />
                Login with your institution
            </VBtn>
            <VAutocomplete 
                prependIcon="fa fa-institution"
                density="compact"
                id="query_idp_autocomplete"
                placeholder="Type institution name"
                no-data-text="No institutions found with this name"
                :items="idps"
                item-title="name"
                item-value="abbreviation"
                single-line
                hide-details
                flat
                v-show="show.sso"
                @update:modelValue="open_idp_login"
                @update:search="query_idps"/>
        </VRow>
        </VContainer>
    </VCard>
</template>

<script>
import shared from '../shared.js'

export default {
    name: "LoginForm",
        data: function() {return({
        csrf_token: false,
        errors: [],
        loading: false,
        idps: [], 
        username: '',
        password: '',
        q: '',
        show: {
            password:1,
            sso:false,
        }
    })},
    mounted: function() {
        this.csrf_token = shared.get_cookie('csrftoken')
    },
    methods: {
        query_idps: async function(query) {
            if (!query || query.length<3) return
            const idp_response =  await this.$axiosQ.jumpQueue('gcapi', {
                method:'post',
                url: '/idps/',
                data: {q: query}}
            )
            this.idps=idp_response.data.idps
        },
        post_login: async function() {
            var _this = this;
            this.$axiosQ.jumpQueue('gcallauth', {
                method: 'post', 
                url: "/auth/login",
                data: {
                    "username": this.username,
                    "password": this.password,
                }
            }).then(response => {
                console.log(response)
                window.location.href=this.$route.query?.next??"/app/"
            })
            .catch(axios_error => {
                console.log('failed_response', axios_error)
                this.$emit('formThrowsError', axios_error.response.data)
            })
        },    
        required (v) {
            return !!v || 'Field is required'
        },
        open_idp_login: function(sso_entity_id) {
            // go forward to wayf
            console.log('posting login to', sso_entity_id)
            const sso_form = document.getElementById('sso_form');
            // Ensure the form exists before submitting
            if (sso_form) {
                document.getElementById('sso_provider').value = sso_entity_id
                sso_form.submit(); // Submit the form
            }
        }
    }
}
</script>

<style>

</style>