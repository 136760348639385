<template>
    <v-expansion-panel class="team_panel">
        <v-expansion-panel-title class="team-title almost_black">
            <span><i class="fa fa-user-group me-2 text-secondary"></i></span>   
            {{team.name}}
            <VBbadge color="secondary" :content="team.users.length" inline />
        </v-expansion-panel-title>
        <VExpansionPanelText>
            <VAlert :key="'error'+idx" class="error-message mt-2" v-for="(errorMessage, idx) in messages" type="error" dense>
                {{ errorMessage }}
            </VAlert>
            <VChipGroup>
                <VChip label 
                    :key="user.username" v-for="user in team.users">
                    {{user.first_name}} {{user.last_name}}
                    <template v-slot:append>
                        <span
                            title="Remove user from group"
                            class="remove_user_from_team hover-red"
                            @click="remove_user_from_team(user, team)">
                            <i class="ms-1 fa fa-xmark"></i>
                        </span>
                    </template>
                </VChip>
                <div v-if="!team.users?.length">Team has no teammembers.</div>
            </VChipGroup>
            <VCol class="my-2">
                <VRow justify="space-between">
                    <VMenu 
                    :close-on-content-click="false"
                    offset-x
                    >
                        <template v-slot:activator="{ props }">
                            <VBtn class="btn-primary" v-bind="props">Add a user to team {{team.name}}</VBtn>
                        </template>
                        <VCard>
                            <VAutocomplete
                                :items="addable_users"
                                :item-title="'first_name'"
                                :item-value="'username'"
                                label="Add a user"
                                multiple
                                hide-details
                                hide-selected
                                :return-object="true"
                                chips
                                v-model="additions"
                            >
                            </VAutocomplete>
                            <VBtn block class="text-primary" 
                                @click="add_users_to_team(team)"
                                :loading="loading"
                                variant="elevated"
                            >
                                Add
                                <template :slot="loading">
                                    <VProgressCircular
                                    color="primary"
                                    indeterminate
                                    ></VProgressCircular>
                                </template>
                            </VBtn>
                        </VCard>
                    </VMenu>
                    <VBtn color="danger" variant="outlined"
                        :loading="deleting" prepend-icon="fa fa-trash" @click="delete_team(team)">
                        Delete team
                    </VBtn>
                </VRow>
            </VCol>
        </VExpansionPanelText>
    </v-expansion-panel>
</template>

<script>
export default {
    name: "Team",
    props: ["team", "users"],
    computed: {
        addable_users: function() {
            return this.users.filter(user => !_.map(this.team.users, 'username').includes(user.username))
        },
    },
    data: function() {return{
        loading: false,
        deleting: false,
        messages: [],
        additions: [],
    }},
    methods: {
        delete_team: async function(team) {
            this.$emit('delete_team', team)
        },
        update_team: async function() {
            let _this = this
            this.loading = true
            await this.$axiosQ.jumpQueue('gcapi', {
                method: 'patch', 
                url: `/api/teams/${this.team.uuid}/`,
                data: {
                    users: this.team.users
                }
            }).catch(axios_error => {
                console.log('failed_response', axios_error)
                _this.messages.push(axios_error.response.data.detail)
            })
            this.$emit('refresh_data')
            this.loading = false
        },
        add_users_to_team: function() {
            this.team.users = this.team.users.concat(this.additions)
            this.additions = []
            this.update_team()
        },
        remove_user_from_team: function(remove_user,) {
            console.log('removing user', remove_user, 'from team', this.team)
            this.team.users = this.team.users.filter(user => {return remove_user.username != user.username})
            this.update_team()
        },
    }
}
</script>

<style>

</style>