<template>
  <div id="module-picker" :class='{
            "emphasis": show.emphasis=="HomeSearchModuleSelector",
            "greyed_out": show.emphasis=="SearchBox",
            "minimized": show.emphasis=="SimilarResults",
        }' class="mw-100">
    <h5 class="mb-4" v-if="show.emphasis=='HomeSearchModuleSelector'">What are you looking for today?</h5>
    <VChipGroup :center-active="true" :mobile="false" :column="show.emphasis=='HomeSearchModuleSelector'" mandatory class="d-flex justify-content-between align-items-center" id="module-buttons">
        <template :key="searchmodule.pk" v-for="(searchmodule, index) in searchmodules">
          <VChip
            size="small"
            variant="text"
            @click="select_or_search(searchmodule)"
            :class="{'active': multiselect_searchmodule === searchmodule.pk}"
            class="btn-searchmodule"
            role="button"
            :ripple="false"
            >
              <span class="searchmodule-button-text">{{ searchmodule.display_name }}</span>
            </VChip>
          <VDivider v-if="index !== (searchmodules.length-1)" vertical color="greytext" thickness="1" length="32px"></VDivider>
        </template>  
    </VChipGroup>
  </div>
</template>

<script>
import { mapState } from "vuex";
import shared from "./shared";
import GreyDivider from "./GreyDivider.vue";

export default {
  name: "HomeSearchModuleSelector",
  components: {GreyDivider},
  computed: {
    ...mapState(["searchmodules", "show"]),
    multiselect_searchmodule:{
      get: function() {return this.$store.state.selected_searchmodule.pk},
      set: function(value) {
        console.log('setting via setter', value)
        select_or_search(value);
      }
    }
  },
  methods: {
    get_icon: function (result_type) {
      return shared.result_type_to_icon(result_type);
    },
    set_with_select: function(option_primary_key) {
      let searchmodule = this.searchmodules.filter(function(searchmodule) {return searchmodule.pk == option_primary_key})[0]
      this.select_or_search(searchmodule)
    },
    select_or_search: function(searchmodule) {
      this.$store.commit('SET_SEARCHMODULE', searchmodule)
      if(!this.$store.state.show.search_started) {
        this.$store.state.show['emphasis'] = 'SearchBox' // Move on UI emphasis to Search field
        document.querySelector('#smart_query').focus()
      }
      else {
        this.$store.dispatch('find_similar')
      }
    }
  },
};
</script>

<style>
/* default */
#module-buttons {
  margin: 0 auto;
  color:var(--grey_text);
  width:80%;
}
.v-slide-group__content {
  justify-content:space-between;
  align-items:center;
  height:40px;
}
.v-chip-group .v-chip{
  margin: 4px!important;
}

#module-picker.minimized #module-buttons {
  width:100%;
}

.btn-searchmodule {
  overflow:visible;
}

.btn-searchmodule.active {
  color: var(--primary_color);
  font-weight:700;
}
.grey-divider {
  flex-shrink:1;
}
.searchmodule-button-text {
  overflow-x:visible;
  flex-basis:0;
  width: 100%;
  display:inline-block;
}

.searchmodule-button-text  {
  font-size:16px!important;
  transition: all var(--fast_anim) ease;
}

.btn-searchmodule:hover .searchmodule-button-text {
  color: var(--primary_color);
  transform:scale(1.1);
  background:transparent!important;
}


/* emphasized status: */
@media (max-width: 992px) {
  #module-picker.emphasis {
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    transform: translateY(100%);
  }
}
@media (min-width: 992px) {
  #module-picker.emphasis {
      top: 50%;
      -webkit-transform: translateX(-50%) translateY(50%);
      -moz-transform: translateX(-50%) translateY(50%);
      transform: translateX(-50%) translateY(50%);
  }
}

#module-picker.emphasis h5 {
    text-align:center;
    color:var(--secondary_color)
}
#module-picker.emphasis .btn-primary:hover {
    color: var(--orange_highlight);
    border-color: var(--orange_highlight);
}
#module-picker.emphasis .btn-primary {
    color:var(--grey_text);
}


/* minimized status */
#module-picker {
  position:absolute;
  width:100%;
  top: 25%;
}
@media (max-width: 992px) {
  #module-picker.minimized {
    position:relative;
  }
}
@media (min-width: 992px) {
  #module-picker {
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(25%);
    -moz-transform: translateX(-50%) translateY(25%);
    transform: translateX(-50%) translateY(25%);
  }
  #module-picker.minimized {
    height:55px;
    position:absolute;
    width:40%;
    left: 0;
    top: .5rem;
    -webkit-transform: translateX(0%) translateY(0);
    -moz-transform: translateX(0%) translateY(0);
    transform: translateX(0%) translateY(0);
  }
}
</style>