<template>
    <VListItem class="pa-0 ">
        <VListItemTitle class="work d-flex justify-content-between align-items-center" @click="$store.dispatch('get_item', {'popup_anchor': $event.target, 'item_id': work.identifier, 'item_data': work})">
            <div class="work-title align-items-center">
                <i class="fa fa-file-text mx-1"></i>
                <div class="text-truncate">{{ work.fields.title }}</div>
            </div>
            <VChip size="small" density="comfortable" class="flex-shrink-0 year-label" v-if="work.fields?.publication_year" label variant="tonal">{{ work.fields.publication_year }}</VChip>
        </VListItemTitle>
    </VListItem>
</template>

<script>
export default {
    name:"Work (in subcollection)",
    props: ['work']
}
</script>

<style>
.work {
    border-radius: 4px;
    /* background-color:white;
    box-shadow: var(--box_shadow); */
    padding: 0;
    width:var(--resultlist_width);
    text-align:left;
    cursor:pointer;
    display:flex;
    overflow:hidden;
}
.work-title {
    display:flex;
    max-height:1.45rem;
    overflow:hidden;
}
.work:hover, .work:hover svg {
    background-color: var(--primary_color);
    color: white;
}
.work svg {
    color:var(--primary_color);
}
.year-label {
    width:3rem;
    text-align:center;
}
</style>