<template>
    <div id="country_filter_div">
        <div for="country_filter" class="sidebar-title" title='Include or exclude only the following countries'>Affiliation Country</div>
		<Multiselect
			id="country_filter"
			mode="tags"
			label="name"
			valueProp="code"
			:appendToBody="true"
			:trackBy="['name', 'code']"
			:searchable="true"
			placeholder="Select or type a country"
			v-model="authors_country_code"
			:object="false" 
			:groups="true"
			:groupSelect="false"
			:attrs='{"autocomplete": "one-time-code"}'
			:class="{'active' : (authors_country_code?.length)}"
			:caret="false"
			:canClear="true"
			:options="scope == 'nl_only'?nl_only:countries" 
		>
			<template v-slot:tag="{ option, handleTagRemove, disabled }">
				<div class="multiselect-tag is-user country"
					:class="{
						'is-disabled': disabled
					}"
				>
				<span v-if="!option.children.length" :class="'me-1 flex-shrink-0 flag:'+option.code"></span>
				<span v-else title="Click to replace with constituent countries." 
					class="expand_country_group"
					@click.stop="expand_option(option)">
					<i class="fa fa-angle-down me-1"></i>
				</span>
				<div class='ellipsis-text'>
						{{ option.name }}
				</div>
				<span
					v-if="!disabled"
					class="multiselect-tag-remove"
					@click="handleTagRemove(option, $event)">
					<span class="multiselect-tag-remove-icon"></span>
				</span>
				</div>
			</template>
		</Multiselect>
		<FilterSlider field='institution.country_code'/>
	</div>
</template>

<script>
import 'country-flag-icons/3x2/flags.css'

export default {

    name: 'CountryFilter',
    props: ['excludes', 'scope'],
    computed: {
        authors_country_code: {
			get: function() {
                if (typeof this.$store.state.filters['institution.country_code'] === 'undefined') return []
				// Because the store might have individual countries that together form an entire group,
				// we have to replace the full groups.
				var value = this.$store.state.filters['institution.country_code']['values']
				if (!value || !value.length) return []
				this.countries[0]['options'].forEach(country_group => {
					// those that have been manually expanded, we do not collapse.
					if (country_group['expanded']) {return true;}
					var hasAllCountryMembers = country_group['children'].every(country => value.includes(country))
					if(hasAllCountryMembers) {
						// only keep the other values
						value = value.filter(country => !country_group['children'].includes(country))
						// and replace the members with its' group
						if (!value.includes(country_group['code'])) {
							value.push(country_group['code'])
						}
					}
				})
				return value
			},
			set: function(value) {
				// the Multiselect element has groups defined in this.countries[0].options,
				// those should be expanded to indidivual countries if those codes are in the field.
				console.log('value to set to:', value)
				const sorted_country_groups = this.countries[0]['options'].sort((a,b) => {return b.children.length - a.children.length})
				sorted_country_groups.forEach(country_group => {
					if (value.indexOf(country_group['code']) > -1) {
						value.splice(value.indexOf(country_group['code']), 1)
						country_group.children.forEach(country => {
							// upon expanding, add the ones not already present.
							if (!value.includes(country)) {
								console.log('adding child country', country)
								value.push(country)
							}
						})
					}
				})
				console.log('value after expansion', value)
				this.$store.commit('SET_FILTER', {
					field: 'institution.country_code', 
					type: this.$store.getters.getExcludedFilterFields.includes('institution.country_code')?'exclude':'include',
					values: value
				})	
			}
        },
    },
	methods: {
		expand_option(expanding_group) {
			console.log('expanding', expanding_group)
			this.countries[0]['options'].forEach(country_group => {
				// we set an expanded bool to prevent re-collapsing.
				if (country_group.code == expanding_group.code) {
					country_group['expanded'] = true
				}
			})
			this.authors_country_code = this.authors_country_code.filter(country => {
				return country != expanding_group['code'] || !expanding_group.children.includes(country)
			})
		},

	},
    data: function() {return({
		post_enrich_field: {
			"Works": "institution.country_code",
			"Experts": "'institution.country_code'",
			"Institutions": "country_code",
		},
		nl_only: [
			{
				"name": "Netherlands",
				"code": "NL",
				"group": false
			}
		],
		countries: [
			{
				"label": "Country Groups",
				"options": [
					{'code': 'ASEAN', 'name': 'ASEAN', 'children': ["BN", "KH", "ID", "LA", "MY", "MM", "PH", "SG", "TH", "VN"]},
					{'code': 'BRICS', 'name': 'BRICS', 'children': ["BR", "RU", "IN", "CN", "ZA"]},
					{'code': 'EEA', 'name': 'European Economic Area', 'children': 
						["AT", "BE", "BG", "HR", "CY", "CZ", "DK", "EE", "FI", "FR", "DE", "GR", "HU", "IE", "IT", "LV", "LT", "LU", "MT", "NL", "PL", "PT", "RO", "SK", "SI", "ES", "SE", "IS", "LI", "NO"]},
					{'code': 'EU', 'name': 'European Union', 'children': 
						["AT", "BE", "BG", "HR", "CY", "CZ", "DK", "EE", "FI", "FR", "DE", "GR", "HU", "IE", "IT", "LV", "LT", "LU", "MT", "NL", "PL", "PT", "RO", "SK", "SI", "ES", "SE"]},
					{'code': 'G7', 'name': 'G7', 'children': ["CA", "FR", "DE", "IT", "JP", "GB", "US"]},
					{'code': 'G20', 'name': 'G20', 'children': ["AR", "AU", "BR", "CA", "CN", "FR", "DE", "IN", "ID", "IT", "JP", "KR", "MX", "RU", "SA", "ZA", "TR", "GB", "US"]},
					{'code': 'NATO', 'name': 'NATO', 'children': [
						"AL", "BE", "BG", "CA", "HR", "CZ", "DK", "EE", "FI", "FR", 
						"DE", "GR", "HU", "IS", "IT", "LV", "LT", "LU", "ME", "NL",
						"MK", "NO", "PL", "PT", "RO", "SK", "SI", "ES", "TR", "GB", "US"
					]},
				]
			},
			{
				"label": "Individual Countries",
				"options": [
				{'code': 'AF', 'name': 'Afghanistan', 'children': []},
				{'code': 'AX', 'name': 'Åland Islands', 'children': []},
				{'code': 'AL', 'name': 'Albania', 'children': []},
				{'code': 'DZ', 'name': 'Algeria', 'children': []},
				{'code': 'AS', 'name': 'American Samoa', 'children': []},
				{'code': 'AD', 'name': 'Andorra', 'children': []},
				{'code': 'AO', 'name': 'Angola', 'children': []},
				{'code': 'AI', 'name': 'Anguilla', 'children': []},
				{'code': 'AG', 'name': 'Antigua and Barbuda', 'children': []},
				{'code': 'AR', 'name': 'Argentina', 'children': []},
				{'code': 'AM', 'name': 'Armenia', 'children': []},
				{'code': 'AW', 'name': 'Aruba', 'children': []},
				{'code': 'AU', 'name': 'Australia', 'children': []},
				{'code': 'AT', 'name': 'Austria', 'children': []},
				{'code': 'AZ', 'name': 'Azerbaijan', 'children': []},
				{'code': 'BS', 'name': 'Bahamas', 'children': []},
				{'code': 'BH', 'name': 'Bahrain', 'children': []},
				{'code': 'BD', 'name': 'Bangladesh', 'children': []},
				{'code': 'BB', 'name': 'Barbados', 'children': []},
				{'code': 'BY', 'name': 'Belarus', 'children': []},
				{'code': 'BE', 'name': 'Belgium', 'children': []},
				{'code': 'BZ', 'name': 'Belize', 'children': []},
				{'code': 'BJ', 'name': 'Benin', 'children': []},
				{'code': 'BM', 'name': 'Bermuda', 'children': []},
				{'code': 'BT', 'name': 'Bhutan', 'children': []},
				{'code': 'BO', 'name': 'Bolivia (Plurinational State of)', 'children': []},
				{'code': 'BQ', 'name': 'Bonaire, Sint Eustatius and Saba', 'children': []},
				{'code': 'BA', 'name': 'Bosnia and Herzegovina', 'children': []},
				{'code': 'BW', 'name': 'Botswana', 'children': []},
				{'code': 'BV', 'name': 'Bouvet Island', 'children': []},
				{'code': 'BR', 'name': 'Brazil', 'children': []},
				{'code': 'IO', 'name': 'British Indian Ocean Territory', 'children': []},
				{'code': 'UM',
				'name': 'United States Minor Outlying Islands',
				'children': []},
				{'code': 'VG', 'name': 'Virgin Islands (British)', 'children': []},
				{'code': 'VI', 'name': 'Virgin Islands (U.S.)', 'children': []},
				{'code': 'BN', 'name': 'Brunei Darussalam', 'children': []},
				{'code': 'BG', 'name': 'Bulgaria', 'children': []},
				{'code': 'BF', 'name': 'Burkina Faso', 'children': []},
				{'code': 'BI', 'name': 'Burundi', 'children': []},
				{'code': 'KH', 'name': 'Cambodia', 'children': []},
				{'code': 'CM', 'name': 'Cameroon', 'children': []},
				{'code': 'CA', 'name': 'Canada', 'children': []},
				{'code': 'CV', 'name': 'Cabo Verde', 'children': []},
				{'code': 'KY', 'name': 'Cayman Islands', 'children': []},
				{'code': 'CF', 'name': 'Central African Republic', 'children': []},
				{'code': 'TD', 'name': 'Chad', 'children': []},
				{'code': 'CL', 'name': 'Chile', 'children': []},
				{'code': 'CN', 'name': 'China', 'children': []},
				{'code': 'CX', 'name': 'Christmas Island', 'children': []},
				{'code': 'CC', 'name': 'Cocos (Keeling) Islands', 'children': []},
				{'code': 'CO', 'name': 'Colombia', 'children': []},
				{'code': 'KM', 'name': 'Comoros', 'children': []},
				{'code': 'CG', 'name': 'Congo', 'children': []},
				{'code': 'CD', 'name': 'Congo (Democratic Republic of the)', 'children': []},
				{'code': 'CK', 'name': 'Cook Islands', 'children': []},
				{'code': 'CR', 'name': 'Costa Rica', 'children': []},
				{'code': 'HR', 'name': 'Croatia', 'children': []},
				{'code': 'CU', 'name': 'Cuba', 'children': []},
				{'code': 'CW', 'name': 'Curaçao', 'children': []},
				{'code': 'CY', 'name': 'Cyprus', 'children': []},
				{'code': 'CZ', 'name': 'Czech Republic', 'children': []},
				{'code': 'DK', 'name': 'Denmark', 'children': []},
				{'code': 'DJ', 'name': 'Djibouti', 'children': []},
				{'code': 'DM', 'name': 'Dominica', 'children': []},
				{'code': 'DO', 'name': 'Dominican Republic', 'children': []},
				{'code': 'EC', 'name': 'Ecuador', 'children': []},
				{'code': 'EG', 'name': 'Egypt', 'children': []},
				{'code': 'SV', 'name': 'El Salvador', 'children': []},
				{'code': 'GQ', 'name': 'Equatorial Guinea', 'children': []},
				{'code': 'ER', 'name': 'Eritrea', 'children': []},
				{'code': 'EE', 'name': 'Estonia', 'children': []},
				{'code': 'ET', 'name': 'Ethiopia', 'children': []},
				{'code': 'FK', 'name': 'Falkland Islands (Malvinas)', 'children': []},
				{'code': 'FO', 'name': 'Faroe Islands', 'children': []},
				{'code': 'FJ', 'name': 'Fiji', 'children': []},
				{'code': 'FI', 'name': 'Finland', 'children': []},
				{'code': 'FR', 'name': 'France', 'children': []},
				{'code': 'GF', 'name': 'French Guiana', 'children': []},
				{'code': 'PF', 'name': 'French Polynesia', 'children': []},
				{'code': 'TF', 'name': 'French Southern Territories', 'children': []},
				{'code': 'GA', 'name': 'Gabon', 'children': []},
				{'code': 'GM', 'name': 'Gambia', 'children': []},
				{'code': 'GE', 'name': 'Georgia', 'children': []},
				{'code': 'DE', 'name': 'Germany', 'children': []},
				{'code': 'GH', 'name': 'Ghana', 'children': []},
				{'code': 'GI', 'name': 'Gibraltar', 'children': []},
				{'code': 'GR', 'name': 'Greece', 'children': []},
				{'code': 'GL', 'name': 'Greenland', 'children': []},
				{'code': 'GD', 'name': 'Grenada', 'children': []},
				{'code': 'GP', 'name': 'Guadeloupe', 'children': []},
				{'code': 'GU', 'name': 'Guam', 'children': []},
				{'code': 'GT', 'name': 'Guatemala', 'children': []},
				{'code': 'GG', 'name': 'Guernsey', 'children': []},
				{'code': 'GN', 'name': 'Guinea', 'children': []},
				{'code': 'GW', 'name': 'Guinea-Bissau', 'children': []},
				{'code': 'GY', 'name': 'Guyana', 'children': []},
				{'code': 'HT', 'name': 'Haiti', 'children': []},
				{'code': 'HM', 'name': 'Heard Island and McDonald Islands', 'children': []},
				{'code': 'VA', 'name': 'Holy See', 'children': []},
				{'code': 'HN', 'name': 'Honduras', 'children': []},
				{'code': 'HK', 'name': 'Hong Kong', 'children': []},
				{'code': 'HU', 'name': 'Hungary', 'children': []},
				{'code': 'IS', 'name': 'Iceland', 'children': []},
				{'code': 'IN', 'name': 'India', 'children': []},
				{'code': 'ID', 'name': 'Indonesia', 'children': []},
				{'code': 'CI', 'name': "Côte d'Ivoire", 'children': []},
				{'code': 'IR', 'name': 'Iran (Islamic Republic of)', 'children': []},
				{'code': 'IQ', 'name': 'Iraq', 'children': []},
				{'code': 'IE', 'name': 'Ireland', 'children': []},
				{'code': 'IM', 'name': 'Isle of Man', 'children': []},
				{'code': 'IL', 'name': 'Israel', 'children': []},
				{'code': 'IT', 'name': 'Italy', 'children': []},
				{'code': 'JM', 'name': 'Jamaica', 'children': []},
				{'code': 'JP', 'name': 'Japan', 'children': []},
				{'code': 'JE', 'name': 'Jersey', 'children': []},
				{'code': 'JO', 'name': 'Jordan', 'children': []},
				{'code': 'KZ', 'name': 'Kazakhstan', 'children': []},
				{'code': 'KE', 'name': 'Kenya', 'children': []},
				{'code': 'KI', 'name': 'Kiribati', 'children': []},
				{'code': 'KW', 'name': 'Kuwait', 'children': []},
				{'code': 'KG', 'name': 'Kyrgyzstan', 'children': []},
				{'code': 'LA', 'name': "Lao People's Democratic Republic", 'children': []},
				{'code': 'LV', 'name': 'Latvia', 'children': []},
				{'code': 'LB', 'name': 'Lebanon', 'children': []},
				{'code': 'LS', 'name': 'Lesotho', 'children': []},
				{'code': 'LR', 'name': 'Liberia', 'children': []},
				{'code': 'LY', 'name': 'Libya', 'children': []},
				{'code': 'LI', 'name': 'Liechtenstein', 'children': []},
				{'code': 'LT', 'name': 'Lithuania', 'children': []},
				{'code': 'LU', 'name': 'Luxembourg', 'children': []},
				{'code': 'MO', 'name': 'Macao', 'children': []},
				{'code': 'MK',
				'name': 'Macedonia (the former Yugoslav Republic of)',
				'children': []},
				{'code': 'MG', 'name': 'Madagascar', 'children': []},
				{'code': 'MW', 'name': 'Malawi', 'children': []},
				{'code': 'MY', 'name': 'Malaysia', 'children': []},
				{'code': 'MV', 'name': 'Maldives', 'children': []},
				{'code': 'ML', 'name': 'Mali', 'children': []},
				{'code': 'MT', 'name': 'Malta', 'children': []},
				{'code': 'MH', 'name': 'Marshall Islands', 'children': []},
				{'code': 'MQ', 'name': 'Martinique', 'children': []},
				{'code': 'MR', 'name': 'Mauritania', 'children': []},
				{'code': 'MU', 'name': 'Mauritius', 'children': []},
				{'code': 'YT', 'name': 'Mayotte', 'children': []},
				{'code': 'MX', 'name': 'Mexico', 'children': []},
				{'code': 'FM', 'name': 'Micronesia (Federated States of)', 'children': []},
				{'code': 'MD', 'name': 'Moldova (Republic of)', 'children': []},
				{'code': 'MC', 'name': 'Monaco', 'children': []},
				{'code': 'MN', 'name': 'Mongolia', 'children': []},
				{'code': 'ME', 'name': 'Montenegro', 'children': []},
				{'code': 'MS', 'name': 'Montserrat', 'children': []},
				{'code': 'MA', 'name': 'Morocco', 'children': []},
				{'code': 'MZ', 'name': 'Mozambique', 'children': []},
				{'code': 'MM', 'name': 'Myanmar', 'children': []},
				{'code': 'NA', 'name': 'Namibia', 'children': []},
				{'code': 'NR', 'name': 'Nauru', 'children': []},
				{'code': 'NP', 'name': 'Nepal', 'children': []},
				{'code': 'NL', 'name': 'Netherlands', 'children': []},
				{'code': 'NC', 'name': 'New Caledonia', 'children': []},
				{'code': 'NZ', 'name': 'New Zealand', 'children': []},
				{'code': 'NI', 'name': 'Nicaragua', 'children': []},
				{'code': 'NE', 'name': 'Niger', 'children': []},
				{'code': 'NG', 'name': 'Nigeria', 'children': []},
				{'code': 'NU', 'name': 'Niue', 'children': []},
				{'code': 'NF', 'name': 'Norfolk Island', 'children': []},
				{'code': 'KP',
				'name': "Korea (Democratic People's Republic of)",
				'children': []},
				{'code': 'MP', 'name': 'Northern Mariana Islands', 'children': []},
				{'code': 'NO', 'name': 'Norway', 'children': []},
				{'code': 'OM', 'name': 'Oman', 'children': []},
				{'code': 'PK', 'name': 'Pakistan', 'children': []},
				{'code': 'PW', 'name': 'Palau', 'children': []},
				{'code': 'PS', 'name': 'Palestine, State of', 'children': []},
				{'code': 'PA', 'name': 'Panama', 'children': []},
				{'code': 'PG', 'name': 'Papua New Guinea', 'children': []},
				{'code': 'PY', 'name': 'Paraguay', 'children': []},
				{'code': 'PE', 'name': 'Peru', 'children': []},
				{'code': 'PH', 'name': 'Philippines', 'children': []},
				{'code': 'PN', 'name': 'Pitcairn', 'children': []},
				{'code': 'PL', 'name': 'Poland', 'children': []},
				{'code': 'PT', 'name': 'Portugal', 'children': []},
				{'code': 'PR', 'name': 'Puerto Rico', 'children': []},
				{'code': 'QA', 'name': 'Qatar', 'children': []},
				{'code': 'XK', 'name': 'Republic of Kosovo', 'children': []},
				{'code': 'RE', 'name': 'Réunion', 'children': []},
				{'code': 'RO', 'name': 'Romania', 'children': []},
				{'code': 'RU', 'name': 'Russian Federation', 'children': []},
				{'code': 'RW', 'name': 'Rwanda', 'children': []},
				{'code': 'BL', 'name': 'Saint Barthélemy', 'children': []},
				{'code': 'SH',
				'name': 'Saint Helena, Ascension and Tristan da Cunha',
				'children': []},
				{'code': 'KN', 'name': 'Saint Kitts and Nevis', 'children': []},
				{'code': 'LC', 'name': 'Saint Lucia', 'children': []},
				{'code': 'MF', 'name': 'Saint Martin (French part)', 'children': []},
				{'code': 'PM', 'name': 'Saint Pierre and Miquelon', 'children': []},
				{'code': 'VC', 'name': 'Saint Vincent and the Grenadines', 'children': []},
				{'code': 'WS', 'name': 'Samoa', 'children': []},
				{'code': 'SM', 'name': 'San Marino', 'children': []},
				{'code': 'ST', 'name': 'Sao Tome and Principe', 'children': []},
				{'code': 'SA', 'name': 'Saudi Arabia', 'children': []},
				{'code': 'SN', 'name': 'Senegal', 'children': []},
				{'code': 'RS', 'name': 'Serbia', 'children': []},
				{'code': 'SC', 'name': 'Seychelles', 'children': []},
				{'code': 'SL', 'name': 'Sierra Leone', 'children': []},
				{'code': 'SG', 'name': 'Singapore', 'children': []},
				{'code': 'SX', 'name': 'Sint Maarten (Dutch part)', 'children': []},
				{'code': 'SK', 'name': 'Slovakia', 'children': []},
				{'code': 'SI', 'name': 'Slovenia', 'children': []},
				{'code': 'SB', 'name': 'Solomon Islands', 'children': []},
				{'code': 'SO', 'name': 'Somalia', 'children': []},
				{'code': 'ZA', 'name': 'South Africa', 'children': []},
				{'code': 'GS',
				'name': 'South Georgia and the South Sandwich Islands',
				'children': []},
				{'code': 'KR', 'name': 'Korea (Republic of)', 'children': []},
				{'code': 'SS', 'name': 'South Sudan', 'children': []},
				{'code': 'ES', 'name': 'Spain', 'children': []},
				{'code': 'LK', 'name': 'Sri Lanka', 'children': []},
				{'code': 'SD', 'name': 'Sudan', 'children': []},
				{'code': 'SR', 'name': 'Suriname', 'children': []},
				{'code': 'SJ', 'name': 'Svalbard and Jan Mayen', 'children': []},
				{'code': 'SZ', 'name': 'Swaziland', 'children': []},
				{'code': 'SE', 'name': 'Sweden', 'children': []},
				{'code': 'CH', 'name': 'Switzerland', 'children': []},
				{'code': 'SY', 'name': 'Syrian Arab Republic', 'children': []},
				{'code': 'TW', 'name': 'Taiwan', 'children': []},
				{'code': 'TJ', 'name': 'Tajikistan', 'children': []},
				{'code': 'TZ', 'name': 'Tanzania, United Republic of', 'children': []},
				{'code': 'TH', 'name': 'Thailand', 'children': []},
				{'code': 'TL', 'name': 'Timor-Leste', 'children': []},
				{'code': 'TG', 'name': 'Togo', 'children': []},
				{'code': 'TK', 'name': 'Tokelau', 'children': []},
				{'code': 'TO', 'name': 'Tonga', 'children': []},
				{'code': 'TT', 'name': 'Trinidad and Tobago', 'children': []},
				{'code': 'TN', 'name': 'Tunisia', 'children': []},
				{'code': 'TR', 'name': 'Turkey', 'children': []},
				{'code': 'TM', 'name': 'Turkmenistan', 'children': []},
				{'code': 'TC', 'name': 'Turks and Caicos Islands', 'children': []},
				{'code': 'TV', 'name': 'Tuvalu', 'children': []},
				{'code': 'UG', 'name': 'Uganda', 'children': []},
				{'code': 'UA', 'name': 'Ukraine', 'children': []},
				{'code': 'AE', 'name': 'United Arab Emirates', 'children': []},
				{'code': 'GB',
				'name': 'United Kingdom of Great Britain and Northern Ireland',
				'children': []},
				{'code': 'US', 'name': 'United States of America', 'children': []},
				{'code': 'UY', 'name': 'Uruguay', 'children': []},
				{'code': 'UZ', 'name': 'Uzbekistan', 'children': []},
				{'code': 'VU', 'name': 'Vanuatu', 'children': []},
				{'code': 'VE', 'name': 'Venezuela (Bolivarian Republic of)', 'children': []},
				{'code': 'VN', 'name': 'Viet Nam', 'children': []},
				{'code': 'WF', 'name': 'Wallis and Futuna', 'children': []},
				{'code': 'EH', 'name': 'Western Sahara', 'children': []},
				{'code': 'YE', 'name': 'Yemen', 'children': []},
				{'code': 'ZM', 'name': 'Zambia', 'children': []},
				{'code': 'ZW', 'name': 'Zimbabwe', 'children': []}
			]
		}]
    })}

}
</script>

<style>
.country{ 
	background:#f2f1f0;
	color:var(--secondary_color);
}
.ellipsis-text {
	display: flex;
    align-items: center;
}
.expand_country_group:hover {
  color: var(--orange_highlight);
}
</style>