<template>
  <div>
    <VCard title="Change Password" class="pa-6" max-width="400" outlined>
        <VAlert :key="'error'+idx"  v-for="(errorMessage, idx) in messages.errors" type="error" dense>
            {{ errorMessage.message }}
        </VAlert>
        <VAlert :key="'success'+idx"  v-for="(successMessage, idx) in messages.successes" type="success" dense>
            {{ successMessage.message }}
        </VAlert>
        <VForm @submit.prevent>
            <div v-if="password_reset_key">
                <input type="hidden" name="" >
            </div>
            <div v-else>
                <input type="hidden" name="username" id="username" :value="username">
                <VTextField
                    v-model="password.current"
                    label="Current Password"
                    autocomplete="current-password"
                    :rules="[rules.required, rules.min]"
                    :type="show_password.current ? 'text' : 'password'"
                    >
                    <template v-slot:append>
                        <span @click="show_password.current=0" v-if="show_password.current">
                        <i class="fa fa-eye"></i>
                        </span>
                        <span @click="show_password.current=1" v-else>
                        <i class="fa fa-eye-slash"></i>
                        </span>
                    </template>
                </VTextField>
            </div>      
            <VTextField
                v-model="password.new"
                id="new-password"
                autocomplete="new-password"
                :rules="[rules.required, rules.min]"
                :type="show_password.new ? 'text' : 'password'"
                label="New password"
                >
                <template v-slot:append>
                    <span @click="show_password.new=0" v-if="show_password.new">
                    <i class="fa fa-eye"></i>
                    </span>
                    <span @click="show_password.new=1" v-else>
                    <i class="fa fa-eye-slash"></i>
                    </span>
                </template>
            </VTextField>        
            <VTextField
                v-model="password.confirm"
                :rules="[rules.required, rules.min, rules.confirm_new]"
                id="confirm-password"
                autocomplete="new-password"
                :type="show_password.confirm ? 'text' : 'password'"
                label="Confirm new password"
                >
                <template v-slot:append>
                    <span @click="show_password.confirm=0" v-if="show_password.confirm">
                    <i class="fa fa-eye"></i>
                    </span>
                    <span @click="show_password.confirm=1" v-else>
                    <i class="fa fa-eye-slash"></i>
                    </span>
                </template>
            </VTextField>
            <VBtn class="mt-4 d-flex entrance-submit" id="update_password_btn" @click="ChangePassword()">Update password</VBtn>
        </VForm>
    </VCard>
  </div>
</template>

<script>
export default {
    name: 'Change Password',
    props: {
        "username": {
            default:false,
            type: String
        },
        "password_reset_key": {
            default: false,
            type: String
        }
    },
    data: function() {return({
        messages: {
            errors: [],
            successes: [],
        },
        rules: {
            required: value => !!value || 'Required.',
            min: v => v.length >= 14 || 'Min 14 characters',
            confirm_new: v => v === this.password.new || 'Must match the new password', 
        },
        password: {
            current: '',
            new: '',
            confirm: '',
        },
        show_password: {
            current: false,
            new: false,
            confirm: false,
        }
    })},
    methods: {
        async ChangePassword() {
            var _this = this;
            if(this.password_reset_key) {
                this.$axiosQ.jumpQueue('gcallauth', {
                    method: 'post', 
                    url: "/auth/password/reset",
                    data: {
                        "key": this.password_reset_key,
                        "password": this.password.new
                    },
                }).then(response => {
                    console.log(response)
                    window.location.href=this.$route.query?.next??"/app/"
                }).catch(axios_error => {
                    // Django Allauth sends 401 response when reset successful:
                    if(axios_error.response.data.status == 401) {
                        window.location.href="/accounts/login/?reason=password_changed&next="+(this.$route.query?.next??"/app/")
                    }
                    else {
                        this.$emit('formThrowsError', axios_error.response.data)
                    }
                })
            }
            else {
                // Flow when user resets password in account management.
                this.$axiosQ.jumpQueue('gcallauth', {
                    method: 'post', 
                    url: "/account/password/change",
                    data: {
                        "current_password": this.password.current,
                        "new_password": this.password.new
                    }
                }).then(response => {
                    console.log('success message', response)
                    _this.messages.successes.push(
                        {'message': 'Password has been updated successfully.'}
                    )
                })
                .catch(axios_error => {
                    console.log('failed_response', axios_error)
                    _this.messages.errors = axios_error.response.data.errors
                })
            }
        }
    }
}
</script>

<style>

</style>