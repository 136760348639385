<template>
    <div class="mb-3">
        <div class="sidebar-title" :title="description">{{title}}</div>
        <Multiselect
            :mode="mode"
            :label="name"
            valueProp="id"
            :searchable="searchable"
            placeholder="Select a source type" 
            v-model="selected_value"
            :object="false"
            :id="field+'_multiselect'"
            :appendToBody="true"
            :closeOnSelect="true"
            :closeOnDeselect="true"
            :options="options"
            :class="{'active' : selected_value !== null }">
            <template v-slot:option="{ option }">
              <span class="capitalize">{{ option.label }}</span>
            </template>
        </Multiselect>
        <FilterSlider :field='field'/>
    </div>
</template>

<script>

export default {
    name: "CategoricalFilter",
    props: [
        "title",
        "description",
        "field",
        "options",
        'searchable',
        "mode"
    ],
    computed: {
        selected_value: {
            get: function() {
                let value = this.$store.state.filters[this.field]?.values
                if (!value || value == []) {return null}
                else {return value}
            },
            set: function(value) {
                console.log(value)
                if (value == null) {
                    value = []
                }
                this.$store.commit('SET_FILTER', {'field': this.field, 'values': value, 'type': 'include'})
            }
        }
    }
}
</script>

<style>

</style>