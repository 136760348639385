<template>
  <div id="coifinder" class="my-3">
        <div class="sidebar-title" title="Check for potential conflicts of interest with specific authors.">Conflicts of Interest</div>
            <Multiselect
                mode="tags" 
                label="display_name" 
                valueProp="identifier" 
                :searchable="true" 
                placeholder="Type to find authors"
                v-model="coi_authors" 
                :object="true" 
                :filterResults="false" 
                ref="coiauth_id_multiselect"
                :min-chars="3" 
                :delay="150" 
                :appendToBody="true"
                id="coiauth_id_multiselect" 
                :canClear="false"
                :classes="{dropdown: 'multiselect-dropdown multiselect-dropdown-wide'}"
                :attrs='{"autocomplete": "one-time-code"}'
                :class="{'active' : coi_authors?.length}"
                :noOptionsText="'Find with name or ORCID'" 
                :options="query_authors">
                <template v-slot:option="{ option }">
                    <div class="d-flex flex-column flex-grow-1">
                        <div class="d-flex justify-content-between">
                            <span class="fw-bold">{{option.fields.display_name}}</span>
                            <span class="ms-2">{{option.fields.works_count}}<i class="fas fa-book ms-1"></i></span>
                        </div>
                        <span>{{option.fields.hint}}</span>
                    </div>
                </template>
                <template v-slot:caret={}>
                    <span v-if="show.coi_loading"><i class="fas fa-spinner fa-spin me-2"></i></span>
                    <span v-else-if="!coi_authors_has_options"><i class="fas fa-search me-2 text-greyed-out"></i></span>
                    <span v-else class="multiselect-caret" aria-hidden="true"></span>
                </template>
        </Multiselect>
        <span v-if="$store.getters.getAuthorsWithCoI.length && filter_available('excluded_records')"
            @click="hide_authors_with_conflicts()"
            role="button"
            class="mt-1 align-self-end slider-text hide_coi"
            > 
            Hide {{$store.getters.getAuthorsWithCoI.length}} results with conflicts
        </span>
    </div>
</template>

<script>
import shared from '../shared'
import { mapState } from "vuex";

export default {
    name:'CoIFinder',
    computed: {
        ...mapState(["searchmodules", "show"]),
        coi_authors: {
            get() {
                return this.$store.state.search.conflict_of_interest?.records??[]
            },
            set(value) {
                this.$store.dispatch('set_conflict_of_interest_records', value)
            }
        }
    },
    data: () => { return ({
        coi_authors_has_options: false,
        coi_loading:0,
    })},
    methods: {
        query_authors: async function(q) {
            let _this = this;
            if (q) {
                return await this.$axiosQ.jumpQueue('openalex', {
                    method: 'get', 
                    url: "https://api.openalex.org/autocomplete/authors?q=" + shared.latinize(q)}, 2).then(function(response) {
                    let short_ids = _.map(response.data.results, shared.make_author_from_oa_suggestion)
                    _this.coi_authors_has_options = (short_ids.length && short_ids.length > 0);
                    return short_ids;
                })
            }
        },
        hide_authors_with_conflicts: function() {
            let excluded_records_batch = []
            this.$store.getters.getAuthorsWithCoI.forEach(id => {
                let record = this.$store.getters.getCurrentResultListByID[id]
                this.$store.state.search.excluded_records.push(record)
                excluded_records_batch.push(record)
            })
            if (this.$store.state.search.uuid) {
                this.$store.dispatch('add_bulk_records_to_search', {
                    'record_list': excluded_records_batch,
                    'record_model': 'excluded_record'
                })
            }
        },
        filter_available: function(field_name) {
            return this.$store.getters.getCurrentlyAvailableFilters.includes(field_name)
        },
    },
    mounted: function() {
        if(this.$store.state.search.conflict_of_interest?.records?.length && this.$store.getters.getCurrentResultList && this.$store.getters.getCurrentResultList.length) {
            console.log('mounted with CoI in list, dispatching check.')
            this.coi_loading = 1
            this.$store.dispatch('check_conflicts_of_interest').then(() => {this.coi_loading = 0})
        }
        else {
            console.log('mounted but no CoI in check.')
        }
    }
}
</script>

<style>
#coifinder {
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
}
.hide_coi:hover {
    color:var(--orange_highlight);
}
@media(min-width: 1640px) {
    /* on small screens, popper corrects, otherwise we do it ourselves: */
    .multiselect-dropdown-wide {
        margin-left:94px!important;
    }
}
@media(max-width: 1640px) {
    /* on small screens, popper corrects, otherwise we do it ourselves: */
    .multiselect-dropdown-wide {
        margin-left: calc(50vw - 720px + 1rem)!important;
    }
}
@media(max-width: 1440px) {
    /* on small screens, popper corrects, otherwise we do it ourselves: */
    .multiselect-dropdown-wide {
        margin-left: 1.5rem !important;
    }
}
.multiselect-dropdown-wide {
    min-width: 400px;
}
</style>