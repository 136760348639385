<template>
  <div class="mb-4">
    <div class="sidebar-title">{{filter_name}}</div>
    <v-range-slider
        :max="max"
        :min="min"
        :step="step"
        v-model="range"
        class="align-center"
        :thumb-color="filter_active?'primary':'white'"
        track-color='grey_divider'
        :track-fill-color="filter_active?'primary-lighter-1':'grey_divider'"
        hide-details
    >
    </v-range-slider>
    <div class="d-flex justify-content-between" v-if="include_numerical_inputs">
        <div class="" 
            v-for="start_or_end in [0,1]"
            :key="'range_filter_'+field+start_or_end"
            >
            <input :value="range[start_or_end]+(start_or_end&&filter_active&&range[1]===max?'+':'')"
                :id="'range_filter_'+field+start_or_end"
                class="form-control range-input"
                :min="min"
                :max="max"
                :step="step"
                @change="set_num_input(start_or_end, $event)"
            />
        </div>
    </div>
  </div>  
</template>

<script>
import {mapState} from 'vuex'

export default {
    name: 'RangeFilter',
    props: ['min', 'max', 'step', 'filter_name', 'field', 'include_numerical_inputs'],
    computed: {
        ...mapState(['filters']),
        filter_active: function() {
            return this.range[0] != this.min || this.range[1] != this.max
        },
        range: {
            get(){
                if (this.filters[this.field]) {
                    return [
                        this.filters[this.field]['start']?? this.min,
                        this.filters[this.field]['end']?? this.max
                    ]
                }
                else return [this.min,this.max]
            },
            set(value){
                if (value[0] > value[1]) value.reverse()
                if (value[0] <= this.min) value[0] = null;
                if (value[1] >= this.max) value[1] = null;
                const filter_obj = {
                    'field': this.field,
                    'type': 'range',
                    'start': value[0],
                    'end': value[1] 
                }
                this.$store.commit('SET_FILTER', filter_obj)
            }
        }
    },
    methods: {
        set_num_input(range, $event) {
            console.log('setting num input', range, $event.target.value)
            const current_range = this.range
            current_range[range] = parseInt($event.target.value) 
            this.range = current_range
        }
    }
}
</script>
<style>
.range-input {
    padding:6px 4px!important;
    text-align:center;
    position:relative;
    max-width:50px;
    appearance: textfield;
    -moz-appearance: textfield;
}
.fixed_inputs {
    position:relative;
}

</style>