<template>
    <div v-if="item.fields &&
               item.identifier &&
               $store.state.selected_searchmodule.allowed_api_endpoints.includes('use_collections')"
            class="btn-primary d-flex flex-align-center label-button me-2 btn-small grey-text" 
            :class="{'in_collection': in_collection}" 
            @click.stop="toggle_collection($event)"
    >
        <span v-if="in_collection" class="in_collection"><span class="text-emphasis">REMOVE from collection</span></span>
        <span class="not_in_collection" v-else><span class="collection-text-emphasis">ADD</span> to collection</span>
    </div>
</template>

<script>
import $ from 'jquery'

export default {
    name: "LabelBox",
    computed: {
        in_collection: function() {
            if(!this.$store.state.search.included_records.length) { return false}
            if(typeof this.item.identifier === 'undefined'){ return false;}
            return this.$store.state.search.included_records.map(item => item.identifier).indexOf(this.item.identifier) > -1
        },
    },
    props: ['item'],
    methods: {
        toggle_collection: function($event) {
            var labelbox = $event.target.getBoundingClientRect()
            var counter = $('#collection-counter')[0].getBoundingClientRect()
            if (!this.in_collection) {
                $('#collectable').css({top: labelbox.top+0.5*labelbox.height+'px', left: labelbox.left+0.5*labelbox.width+'px'});
                $('#collectable').animate({opacity:1},10).animate({left: counter.left+0.5*counter.width+'px', top: counter.top+0.5*counter.height+'px'}, 300).animate({opacity:0},10)
                this.$store.commit('ADD_TO_INEX_COLLECTION', {'record_to_add': this.item, 'record_model': 'included_record'})
            }
            else {
                $('#collectable').css({top: counter.top+'px', left: counter.left+'px'});
                $('#collectable').animate({opacity:1},10).animate({left: labelbox.left+0.5*labelbox.width+'px', top: labelbox.top+0.5*labelbox.height+'px'}, 300).animate({opacity:0},10)
                this.$store.commit('REMOVE_FROM_INEX_COLLECTION', {'record_to_remove': this.item, 'record_model': 'included_record'})
            }
        }
    }
}
</script>

<style>
    .collection-text-emphasis {
        font-weight:900;
        color:var(--orange_highlight)!important;
    }
    .label-button:hover, .in_collection .text-emphasis {
        color:white!important;
    }
    #list-container div.in_collection {
        background-color:var(--orange_highlight);
        color:white;
        box-shadow: inset 0px 3px 6px #00000029;
    }
    .btn-small {
        padding:.25rem .5rem !important;
        font-size:.8rem !important;
    }
</style>