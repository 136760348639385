<template>
<VExpansionPanelTitle class="filter-group-title" :static="true">
    <template v-slot:actions="{ expanded }">
        <div class="d-flex justify-content-between align-items-center">
            <span class="animate-flip" :class="{'flip-y':expanded}"><i class='fa fa-chevron-down'></i></span>
        </div>
    </template>
    <template v-slot:default="{}">
        <div>{{filtergroupname}}</div>
    </template>
</VExpansionPanelTitle>
</template>

<script>
export default {
    name: 'Filter Group Title',
    props: ['filtergroupname'],
}
</script>

<style>
.filter-group-title {
    padding: .5rem 1.5rem !important;
    font-size:1rem;
    font-weight: 500;
    border-top:1px solid var(--grey_divider);
    background-color:white;
}
.v-expansion-panel-title--active {
    background-color:transparent;
}
</style>