<template>
  <div id="query_container" :class='{
            "not_visible":show.emphasis=="HomeSearchModuleSelector",
            "minimized": show.emphasis=="SimilarResults",
        }'>
        <div class="mb-3 text-center" v-if="show.emphasis=='SearchBox'">{{placeholder}}</div>
        <div id="query_box" class="form-control d-flex align-items-center">
            <div class="search-button" @click="submit_query($event, false)"><i class="fa fa-search flip-x" ></i></div>
            <div class="d-flex w-100 align-items-center">
                <div class="searchbox me-4" 
                     v-if="searchbox.record_type == 'author' && !textarea_focus" 
                     @click="dropdown_open = 1 - dropdown_open"
                     >
                     <div>
                        <span class="author"><i class="fa fa-mortar-board"></i> {{searchbox.fields.display_name}}</span>
                    </div>
                    <span id="remove_query_option" class="multiselect-clear-icon" @click.stop="remove_option()"></span>
                </div>
                <textarea id="smart_query" v-show="searchbox.identifier == 'identifier~~query_text' || textarea_focus" rows="1" 
                    @focus="activate_textarea(false)"
                    @blur="blur_textarea()"
                    @keydown="handle_typing"
                    v-model="user_input"
                    placeholder="Write or copy your text and press Enter to search.">
                </textarea>
            </div>
        </div>
        <div id="query_dropdown" v-show="$store.getters.getQuerySuggestions.length && show.dropdown == 'search_suggestions'">
                <div :key="option.identifier" 
                    v-for="(option, opt_idx) in $store.getters.getQuerySuggestions" 
                    class="dropdown-option" 
                    :class='{"hovering": opt_idx == dropdown_focus}'
                    @mousedown="select_option(option)">
                    <div v-if="option.identifier == 'identifier~~query_text'">
                        <div class="text-option">
                            <div>
                                <i class="fas fa-font me-2"></i>
                                <span>Find <strong>{{user_input}}</strong> as text.</span>
                            </div>
                            <div>
                                <i class="fa fa-arrow-right"></i>
                            </div>
                        </div>
                    </div>  
                    <div class="author-option" v-if="option.record_type == 'author'">
                        <div class="col-4">
                            <i class="fa fa-mortar-board me-1"></i><span v-html="option.fields.display_name_hl"></span>
                        </div>
                        <div class="col-4 author-option-affiliation">
                            <span v-if="option.fields.hint" v-html="option.fields.hint_hl"></span>
                        </div>
                        <div class="col-4">
                            <span class=""><i class="fas fa-file-text me-1"></i>{{option.fields.works_count}} Works</span>
                            <span class="ms-4"><i class="fas fa-quote-left me-1"></i>{{option.fields.cited_by_count}} Citations</span>
                        </div>
                    </div> 
                </div> 
        </div>
        <p v-if="!show.search_started" class="mt-4 text-center smaller-font">
            <span>A random query: </span>
            <span role="button" class="example-query" @click='load_example(random_example)'>{{random_example}}</span>
        </p>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import shared from './shared.js'
import autosize from 'autosize'

export default {
    name: "SearchBox",
    props: [],
    computed: {
        ...mapState(['show']),
        searchbox: {
            get: function() {
                return this.$store.state.searchbox_collection.records[0]
            },
        },
        autocomplete_authors: {
            get: function() {
                return this.$store.state.autocomplete_authors
            },
            set: function(value) {
                this.$store.state.autocomplete_authors = value
            }
        },
        user_input: {
            get: function() {
                return this.$store.state.searchbox_collection.user_input
            },
            set: function(value) {
                this.$store.state.searchbox_collection.user_input = value
            }
        },
        placeholder: function() {
            if (this.$store.state.selected_searchmodule.allowed_api_endpoints.includes('get_author_abstracts')) { 
                return "Copy and paste a longer text to search, or use the name of an academic as a starting point."
            }
            return  "Copy and paste a longer text to search."
        } 
    },
    data: function() {return({
        random_example: '',
        textarea_focus:0,
        dropdown_focus:0,
        dropdown_open:0,
        debounced_author_query: _.debounce(this.query_authors, 250),
        examples: [
                'The Role of Child and ASD Characteristics in Caregiver-Reported Anxiety: Correlates and Cluster Analyses from a Large Pooled International Database of Autistic Young People',
                'Plant extract mediated cost-effective tin oxide nanoparticles: A review on synthesis, properties, and potential applications',
                'Selective Laser Trabeculoplasty Versus Brimonidine Tartrate 0.2%/Timolol Maleate 0.5% As Adjunct Therapy in Primary Open Angle Glaucoma: A Randomized Prospective Trial',
                'Effect of preoperative keratometry on visual outcomes after small-incision lenticule extraction for myopia',
                'High prevalence of norovirus and rotavirus co-infection in children with acute gastroenteritis hospitalised in Brazzaville, Republic of Congo',
                'ISOF: Information Scheduling and Optimization Framework for Improving the Performance of Agriculture Systems Aided by Industry 4.0',
                'Comparing standard language change dynamics in Flanders and Italy: Style-shifting in Flemish and Italian commercials',
                'State of central and peripheral conducting structures in patients with vibration disease, burdened with metabolic syndrome',
                'Alterations in small RNA profiles in liver following a subchronic exposure to a low-dose pesticide mixture in Sprague-Dawley rats',
                'Royal women and intra-familial diplomacy in late thirteenth-century Anglo-French relations',
                'Short-Run Labor Market Impacts of COVID-19, Initial Policy Measures and Beyond (IZA COVID-19 Crisis Response Monitoring)',
                'A machine learning framework for guided wave-based damage detection of rail head using surface-bonded piezo-electric wafer transducers',
                "The Histories of Alexander Neville (1544-1614): A New Translation of Kett's Rebellion and The City of Norwich",
                "Theoretical aspects of the state and local self- government authorities' relations",
                "21.1 A 28GHz CMOS Phased-Array Beamformer Utilizing Neutralized Bi-Directional Technique Supporting Dual-Polarized MIMO for 5G NR",
                'The legacy of microbial inoculants in agroecosystems and potential for tackling climate change challenges',
                'Device profile of the Vici venous stent for chronic iliofemoral venous obstruction recanalization: overview of its safety and efficacy',
                'Experiments for the Identification of Biosignatures in Ice Grains from Extraterrestrial Ocean Worlds',
                'Forts as a type of defensive structures on the example of fortifications of the remote chora of Chersonesus',
        ],
    })},
    methods: {
        activate_textarea:function(also_focus=false) {
            this.textarea_focus = 1
            this.dropdown_open = 1
            if(also_focus) {
                this.$nextTick(()=>{
                    document.getElementById('smart_query').focus()
                    autosize(document.querySelector('#smart_query'));
                })
            }
        },
        blur_textarea: function() {
            this.dropdown_open = 0
            this.textarea_focus = 0
        },
        load_example: function(example) {
            this.user_input = example
            this.submit_query({}, false)
        },
        handle_typing: function(event) {
            // handle arrow up 
            if (event.keyCode == 38) {
                if (this.dropdown_focus == 0) {this.dropdown_focus = Math.max(this.$store.getters.getQuerySuggestions.length - 1, 0)}
                else {this.dropdown_focus -= 1}    
            }
            // handle arrow down
            else if (event.keyCode == 40) {
                this.dropdown_focus += 1
                if (this.dropdown_focus >= this.$store.getters.getQuerySuggestions.length)  {
                    this.dropdown_focus = 0
                }
            }
            //We submit as text when an enter is pressed without a shift key
            else if (event.keyCode == 13 && !event.shiftKey) {
                event.preventDefault()
                // if there's an option highlighted, we submit that.
                if (this.dropdown_focus !== 0) {
                    this.select_option(this.$store.getters.getQuerySuggestions[this.dropdown_focus])
                }
                // otherwise, we submit as text.
                else {
                    this.submit_query(event, false)
                }
                document.querySelector('#smart_query').blur()
            }
            // on all other keypresses, we update the text and search an author.
            else {
                this.user_input = event.target.value
                this.debounced_author_query()
            }
        },
        select_option: function(option) {
            if (option.identifier == 'identifier~~query_text') {
                return this.submit_query()
            }
            this.$store.commit('SET_SEARCHBOX', {
                records: [option],
                user_input: this.user_input,
            })
            this.$store.dispatch('find_similar')
        },
        remove_option: function() {
            this.$store.commit('SET_SEARCHBOX', {
                records: [{
                    record_type: 'record',
                    identifier: 'identifier~~query_text',
                    fields: {
                        "id": "query_text",
                        'display_name': this.user_input,
                    }
                }],
                user_input: this.user_input,
            })
            this.activate_textarea(true)
        },
        submit_query: function(event, dataset=false) {
            if(dataset) {
                this.$store.commit('SET_SEARCHMODULE', dataset)
            }
            this.$store.commit('SET_SEARCHBOX', {
                records: [{
                    record_type: 'record',
                    identifier: 'identifier~~query_text',
                    fields: {
                        "id": "query_text",
                        'display_name': this.user_input,
                    }
                }],
                user_input: this.user_input,
            })
            this.$store.dispatch('find_similar')
        },
        query_authors: async function() {
            let _this = this;
            // cases when we do not need a dropdown:
            if (_this.user_input.length == null ||
                _this.user_input.length < 3 ||
                _this.user_input.length > 40 ||
                !this.$store.state.selected_searchmodule.allowed_api_endpoints.includes('get_author_abstracts')) { 
                    return false
            }
            else {
                return await this.$axiosQ.jumpQueue('openalex', {
                    method: "get",
                    url: "https://api.openalex.org/autocomplete/authors?author_hint=institution&q=" + shared.latinize(_this.user_input)}, 3).then(function(response) {
                    var oa_query_results = _.map(response.data.results, shared.make_author_from_oa_suggestion);
                    _this.autocomplete_authors = {..._this.autocomplete_authors, ..._.keyBy(oa_query_results, 'identifier')}
                })
            }
        }
    },
    mounted: function() {
        this.random_example = this.examples[Math.floor(Math.random() * this.examples.length)]
        autosize(document.querySelector('#smart_query'));
    },
    watch: {
        // Whether the dropdown should be shown is dependent on two variables:
        // 1) whether there is any text between 1 and 40 characters in the input field
        // 2) whether there is focus on the input element
        dropdown_open: function() {
            if (this.dropdown_open && this.user_input.length > 0 && this.user_input.length < 40) {
                this.$store.commit('SET_DROPDOWN', 'search_suggestions')
            }
            else {this.$store.commit('SET_DROPDOWN', null)}
        },
        user_input: function() {
            if (this.dropdown_open && this.user_input.length > 0 && this.user_input.length < 40) {
                this.$store.commit('SET_DROPDOWN', 'search_suggestions')
            }
            else {this.$store.commit('SET_DROPDOWN', null)}
        }
    }
}
</script>

<style>
    #query_container {
        overflow:hidden;
        display:flex;
        flex-direction:column;
        justify-content:flex-start;
        position:absolute;
        top:calc(0.5*(100vh - 46px));
        bottom:0;
        width:60%;
        right:20%;
        z-index:6;
    }

    /* MOBILE */
    @media (max-width: 992px) {
        #query_container {
            width:auto;
            margin:.5rem;
            right:0;
            left:0;
        }
        #query_container.minimized {
            position:static;
            width:calc(100% - 1rem);
        }
        #query_container.minimized:focus-within {
            width:calc(100% - 1rem)!important;
            overflow-y:visible;
        }
    }
    #query_container.not_visible {
        top:100%;
    }
    #query_container.minimized {
        top: .5rem;
        height: 60px;
        display:inline-block;
        overflow:visible;
        right: 0;
        -webkit-transform: translateX(0%) translateY(0);
        -moz-transform: translateX(0%) translateY(0);
        transform: translateX(0%) translateY(0);
    }

    #query_box {
        box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
        border-radius:var(--ms-radius);
        width:99%!important;
        margin: 0 0.5rem;
        overflow:visible;
        padding: 5px 2px 5px 50px!important;
    }

    #query_box .selectize-dropdown {
        left: calc( 50% - 250px)!important;
        width:500px!important;
    }
    #request_text {
        font-size:16px!important;
        padding:8px!important;
        margin:-4px -0px -4px -8px; 
    }
    #query_box .selectize-input input, .author-request-selectize {
        text-align:center;
    }
    #query_box:hover .search-button {
        padding-left:1rem;
        color:var(--primary_color);
        transition:all ease var(--fast_anim);
    }
    #query_box:focus-within {
        background-color: #fff;
        border-color: var(--primary_color);
    }
    #query_box:hover {
        background-color: #fff;
        border-color: var(--primary_color);
        cursor:text;
    } 
    .search-button{
       cursor:pointer;
       padding: 3px 8px;
       font-size:1.5em;
       position:absolute;
       left:.5rem;
       z-index:10;
    }
    .search-button:hover, .search-type-select:hover {
        color:var(--orange_highlight)!important;
    }
    #query_container.minimized #query_box {
        box-shadow: inset 0px 3px 6px #00000029;
    }
    #query_container.minimized:focus-within {
        width:100%;
        overflow-y:visible;
    }
    .example-query {
        text-decoration: underline;
    }
    .example-query:hover {
        color: var(--orange_highlight)
    }
    .searchbox {
        margin: -0.25rem -0.5rem;
        width: 100%;
        padding: 8px 0px;
        display: flex;
        background: transparent;
        justify-content: flex-start;
        align-items: center;
        border: none;
        font-size: 16px;
        height: 40px;
        overflow-x: hidden !important;
        font-weight:700;
        transition: height ease var(--slow_anim);
    }
    .searchbox .author {
        color: var(--primary_color);
        font-size:16px;
        margin-right:.5rem;
    }
    .searchbox:hover {
        cursor: pointer;        
    }
    .author-option-affiliation {
        max-height: 1.4rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-align:left;
        flex-shrink:1;
    }
    .multiselect-clear-icon:hover {
        background-color: red!important;
    }
    #smart_query {
        margin:-.25rem -.5rem;
        width:100%;
        padding: 8px 0px;
        display:inline-block;
        background:transparent;
        border:none;
        font-size:16px;
        min-height:40px;
        max-height:400px;
        overflow-x:hidden!important;
        transition: height ease var(--slow_anim);
        resize:none;
        z-index:6;
    }
    #smart_query:focus-visible {
        outline:none!important;
    }

    #query_container.minimized:focus-within #smart_query {
        max-height:200px;
    }

    .minimized #smart_query {
        max-height:40px;
    }
    #query_dropdown {
        background:white;
        cursor:pointer;
        margin:0 1rem;
        box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
        border-radius:var(--ms-radius);
        max-height:400px;
        position:relative;
        overflow-y:scroll;
    }
    .author-option, .text-option {
        width:100%;
        display:flex;
        flex-grow:1;
        justify-content:space-between;
        padding: .5rem;
        z-index:6;
    }
    .dropdown-option:hover {
        color: var(--orange_highlight);
    }
    .dropdown-option.hovering {
        background-color: rgba(190, 190, 190, 0.50);
    }
    .flip-x {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
    }
</style>