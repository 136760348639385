<template>
  <div @click.stop="" class="p-4">
    <div class="position-absolute p-4 float-end close-button close-button-work" @click="$store.commit('CLOSE_MODAL')"><i class="fa fa-close"></i></div>
    <h3 class="text-center modal-title mb-3">Co-Authorship overview for {{modal.data.query_author.fields.display_name}}</h3>
      <div class="modal-content">
        <div class="d-flex justify-content-between align-items-center mb-2">
            <div id="text_queries" class="d-flex align-items-center">
                <i class="fas primary_color fa-filter me-2"></i>
                <Multiselect
                    mode="tags"
                    v-model="co_author_modal_text_queries"
                    :searchable="true"
                    :createOption="true"
                    :value="'text'"
                    :label="'text'"
                    @keyup="filter_coauthors"
                    :allowAbsent="true"
                    ref="coauth_textfilter"
                >
                </Multiselect>
            </div>
            <div class="d-flex align-items-center">
                <i class="far primary_color fa-calendar me-2"></i>
                <select id="time_limit" class="form-control" v-model="co_author_modal_time_limit">
                    <option value="1"> Last publication in last year</option>  
                    <option value="3"> Publications from last 3 years</option>  
                    <option value="5"> Publications from last 5 years</option>  
                    <option value="10"> Publications from last 10 years</option>  
                </select>
            </div>
        </div>
        <div>
            <DataTable  id="co_author_table" :options="datatable_options" :columns="columns" :data="coauthor_data" class="table table-striped table-hover w-100">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Publication count</th>
                        <th>Most recent publication</th>
                    </tr>
                </thead>
                <tbody>
                </tbody>
            </DataTable >
        </div>
    </div>
  </div>
</template>

<script>
import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net';
DataTable.use(DataTablesCore);
import 'datatables.net-dt/css/jquery.dataTables.min.css'

export default {
    name: 'CoAuthorModal',
    components: {DataTable},
    props:['modal'],
    computed: {
        coauthor_data: {
            get: function() {
                return this.filter_coauthors(Object.values(this.$store.state.modals['coauthor'].data.coauthors))
            }
        },
        co_author_modal_time_limit: {
            get: function() {
                return this.$store.state.modals.coauthor.time_limit
            },
            set: function(value) {
                this.$store.commit('SET_COAUTH_MODAL', {field: 'time_limit', value: value})
            }
        },
        co_author_modal_text_queries: {
            get: function() {
                return this.$store.state.modals.coauthor.text_queries
            },
            set: function(value) {
                if(value) {
                    this.$store.state.modals.coauthor.text_queries = value
                }
            }  
        },  
    },
    data: function() {return({
        datatable_options: {
            'dom': 't<"d-flex justify-content-between align-items-baseline"il<"#pag"p>>',
            scrollCollapse: true,
            'scrollY': 'calc(50vh - 90px)',
        },
        columns: [
            {name: "Name", data: function(row) {
                return row.authorships[0].author.display_name}
            },
            {name: "Publication count", className: "text-center", data: function(row) {
                return row.works.length}
            },
            {name: "Publication count", className: "text-end", data: function(row) {
                return row.last_publication['publication_date']
                }
            },
        ]
    })},
    mounted: function() {
        var _this = this;
    },
    methods: {
        filter_coauthors() {
            // This function simply filters the list of all co-authors present in the model
            // with the text in the Multiselect. The conditional statements handle the various
            // states of that multiselect, because you also want to filter live while typing.
            let _this = this;
            let unfiltered_coauthor_list = Object.values(this.$store.state.modals['coauthor'].data.coauthors)
            var time_limit = new Date().setFullYear(new Date().getFullYear() - this.co_author_modal_time_limit)
            var published_recently = unfiltered_coauthor_list.map(function(author, index) {
                if (new Date(author.last_publication['publication_date']) < time_limit) {
                    
                }
                else {
                    let works_passing_datefilter = author.works.filter(function(item) {
                        return new Date(item['publication_date']) > time_limit
                    })
                    author.works = works_passing_datefilter
                    return author
                }
            })
            published_recently = published_recently.filter(Boolean)
            let search_value = _this.$refs['coauth_textfilter']?.input.value.toLowerCase()
            // case totally empty
            if (!_this.co_author_modal_text_queries.length && !search_value ){
                console.log('No author string input so returning coauthors recently only.')
                var filtered_coauthor_list = published_recently
            }
            // case typing but none selected
            else if (!_this.co_author_modal_text_queries.length && search_value) {
                var filtered_coauthor_list = published_recently.filter(function(author) {
                    let author_name = author.authorships[0].author.display_name.toLowerCase() 
                    console.log('checking ' + author_name)
                    return author_name.indexOf(search_value) >= 0; 
                })
            }
            // case multiple 
            else{
                var filtered_coauthor_list = published_recently.filter(function(author) {
                    return _this.co_author_modal_text_queries.some(function(q) {
                        let author_name = author.authorships[0].author.display_name.toLowerCase() 
                        let query = q.toLowerCase()
                        if (search_value && search_value.length) {
                            return author_name.indexOf(query) >= 0 || author_name.indexOf(search_value) >= 0 ; 
                        }
                        else {
                            return author_name.indexOf(query) >= 0
                        }
                    })
                })
            }
            return filtered_coauthor_list
        }
    }
}
</script>

<style>
#text_queries {
    flex-grow:1;
    margin-right:2rem;
}

#text_queries .selectize-input {
    min-width:20vw;
}
.close-button:hover {
    color:var(--orange_highlight);
}
.modal-title {
    color:var(--primary_color);
}
.modal-content {
    max-height: calc(80vh - 90px)
}
#pag {
    display:flex;
    align-items:baseline;
}
#pag .paginate_button.current {
    color:white!important;
    background-color:var(--primary_color)!important;
}
#pag .paginate_button:hover {
    background:linear-gradient(to bottom, #585858 0%, var(--primary_color) 100%);
}
#pag .disabled:hover {
    background:transparent;
}
.primary_color {
    color:var(--primary_color)!important;
}
.sorting_asc, .sorting_desc {
    color:var(--primary_color)
} 
#co_author_table {
    overflow-y:auto;
}

</style>