<template>
  <div id="collection-sidebar" class="justify-content-end">
    <div class="w-100" v-if="show.saving_search">
      <Spinner/>
    </div>
      <div v-else>
        <div id="collection_title" class="mt-3 mx-3">
        <div class="d-flex flex-column" v-if="empty_collection && !search.name">
          <span class="h5 mb-3 d-inline-block">Empty collection</span>
        </div>
        <div class="d-flex justify-content-between align-content-baseline" v-else-if="!search.uuid">
          <span class="h5 mb-3 d-inline-block">Unsaved collection</span>
          <span title="Name your collection and press save to persist it.">
            <i class="fa collection-color fa-exclamation-triangle"></i>
          </span>
        </div>
        <div v-else class="h5 mb-3 d-inline-block">{{search.name}}</div>
      </div>
      <div class="mx-3 mb-3" v-if="empty_collection">
        <span class="collection-text-emphasis">ADD</span> or <i class="fa fa-xmark"></i> exclude items to start a collection.
      </div>
      <div class="w-100 btn-collection mb-3" id="save-collection" v-if="!search.uuid && !empty_collection" @click="create_search()">
        Save your collection as:
        <div class="collection_input">
          <input 
            type="text"
            id="collection-title-edit"
            class="form-control"
            @click.stop=""
            @keyup.enter="create_search()"
            v-model="$store.state.search.name"
            placeholder="Please name your collection"
          />
          <span class="save-button"><i class="fa fa-save"></i></span>
        </div>
      </div>
      <div class="d-flex flex-column mb-3 px-3" :key="'records'+type" v-for="(records, type) in this.$store.getters.getCollectionByType">
        <div @click="toggle_type(type)" role="button" class="collection-type d-flex justify-content-between user-select-none align-items-center">
          <span class="sidebar-title capitalize">{{type}} ({{records.length}})</span>
          <span class="animate-flip"  :class="{'flip-y': show['sidebar_collections'][type]}">
            <i class="fa fa-chevron-up"></i>
          </span>
        </div>
        <div v-show="show['sidebar_collections'][type] && !this.show.saving_search">
          <div class="collection-item" :key="'record'+record.identifier" v-for="record in records">
            <span 
              @click="this.$store.dispatch('get_item', {'popup_anchor': $event.target, 'item_id': record.identifier, 'item_data': {'record_type': record.record_type} })"
              role="button"
              class="collection-title"
              >
                {{record.fields.display_name}}
            </span>
            <div class="d-flex">
              <span v-if="index_in_query_collection(record) !== -1" 
                    title="Click to ignore from search"
                    class="collection-item-button search-active"
                    @click="toggle_used_in_search(record)">
                <i  class="fab fa-searchengin "></i>
              </span>
              <span class="collection-item-button search-inactive" v-else @click="toggle_used_in_search(record)" title="Click to use in search.">
                <i class="fab fa-searchengin"></i>
              </span>
              <span class="ms-2 collection-item-button remove" @click="remove_item(record)">
                <i class="fa fa-xmark"></i>
              </span>
            </div>        
          </div>
        </div>
      </div>
      <ExcludedRecordList v-if="filter_available('excluded_records') && search.excluded_records.length"/>       
      <!-- <CoIFinder v-if="filter_available('coi_authors')" key="conflict_of_interest_finder"/> -->
      <div id="collection-buttons" class="mt-3">
        <div class="w-50 d-inline-block">
          <ExcelExport :exporting_items="$store.getters.getCollectionByType" label="Export" :icon="false" classes="btn-collection">
            Export
          </ExcelExport>
        </div>
        <div class="w-50 d-inline-block">
          <div class="btn-collection" @click="$store.dispatch('find_similar')">Refresh</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import shared from '../shared'
import CoIFinder from './CoIFinder.vue'
import ExcelExport from '../ExcelExport.vue'
import ExcludedRecordList from './ExcludedRecordList.vue'
import {mapState} from 'vuex'
import Spinner from '../Spinner.vue'

export default {
  name: "Collection",
  components: {ExcelExport, ExcludedRecordList, CoIFinder, Spinner},
  computed: {
    ...mapState(['search', 'show']),
    empty_collection: function() {
      return !this.search.excluded_records.length && !this.search.included_records.length
    }
  },
  data: function() {return({
  })},
  methods: {
    get_icon: function(result_type) {
        return shared.result_type_to_icon(result_type)
    },
    remove_item(record) {
      this.$store.commit('REMOVE_FROM_INEX_COLLECTION', {
        record_to_remove: record,
        record_model: 'included_record'
      })
    },
    create_search: function() {
      this.$store.dispatch('create_search')
    },
    toggle_type: function(type) {
        if(!this.show['sidebar_collections'][type]) {
          this.show['sidebar_collections'][type] = 1
        }
        else {
          this.show['sidebar_collections'][type] = 1 - this.show['sidebar_collections'][type]
        }
    },
    index_in_query_collection: function(search_record) {
      let _this = this
      return _.findIndex(_this.$store.state.draft_query.collection.records,
        function(query_collection_record) { return query_collection_record.identifier == search_record.identifier; }
      )
    },
    toggle_used_in_search: function(record) {
      var record_index = this.index_in_query_collection(record)
      if (record_index === -1) {
        this.$store.state.draft_query.collection.records.push(record)
      }
      else {
        this.$store.state.draft_query.collection.records.splice(record_index, 1)
      }
    },
    filter_available: function(field_name) {
      return this.$store.getters.getCurrentlyAvailableFilters.includes(field_name)
    },
    check_collection_dirty(e) {
      let _this = this;
      console.log(e)
      console.log('uuid?', _this.search.uuid)
      console.log('empty?', _this.empty_collection)
      if (!_this.empty_collection && !_this.search.uuid) {
          _this.$store.commit('SET_SIDEBAR', 'collection')
          // Standard message for most browsers
          const message = "You have items in a collection, but did not save that collection. If you leave, the collection is lost. Are you sure you want to leave?";           
          // Some browsers require returnValue to be set
          e.returnValue = message; 
          // Returning the message to trigger the confirmation dialog
          return message; 
      }
    }
  },
  mounted: function() {
    // Warn if collection has items but is not saved.
    window.addEventListener('beforeunload',this.check_collection_dirty);
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.check_collection_dirty);
  },
}
</script>

<style>

#empty_collection {
  width:100%;
}
.collection-item {
  display:flex;
  justify-content:space-between;
  box-shadow:var(--box_shadow_emphasis);
  border-radius:var(--ms-radius);
  margin: .25rem 0;
  padding: .25rem .5rem;
  color:var(--orange_highlight);
  background-color:white;
}
.collection-title {
  max-height:1.4rem;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.collection-color {
  color: var(--orange_highlight);
}
.collection-item .title {
  overflow: hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}
.collection-item-button:hover {
  cursor:pointer;
  font-weight:700;
}
.collection-item-button.search-active {
  color:var(--primary_color);
}
.collection-item-button.search-inactive {
  color:var(--grey_text);
}
.collection-item-button.remove{
  color:var(--grey_text);
}
.collection-item-button.remove:hover{
  color:var(--orange_highlight);
}
#collection_title {
  max-width:100%;
  word-wrap:break-word;
}
.collection-type:hover svg {
  color:var(--primary_color);
}
.animate-flip svg {
    transition: transform ease var(--fast_anim);
}
.animate-flip.flip-y svg {
    transform: scaleY(-1);
}
.collection_input {
  position:relative;
  left:0;
  top:5px;
}
#collection-title-edit {
  padding:4px 20px 4px 8px!important;
}
.save-button {
  position:absolute;
  color:var(--primary_color);
  top:.2rem;
  right:.3rem;
}
.save-button:hover {
  color:var(--orange_highlight);
}
.btn-collection {
  flex-grow:1;
  cursor:pointer;
  background:white;
  color:var(--grey_text);
  padding:1rem;
  text-align:center;
}
.btn-collection.red:hover {
  background:var(--bs-danger);
}
#save-collection{
  background-color:var(--orange_highlight);
  color:white;
  font-weight:700;
}
.btn-collection:hover {
  box-shadow: inset 0px 3px 6px #00000029;
  background-color:var(--primary_color);
  color:white;
  font-weight:700;
}
</style>